import { IGetCrawlRequestsQueryVariablesCopy, useGetCrawlRequestsQuery } from '@amgen/api';
import { Paginator, ResultsPerPageDropdown } from '@amgen/components';
import { DEFAULT_SEARCH_QUERY, PageType, RequestStatusType, SortField, useEnvironment } from '@amgen/core';
import {
  IGD_ACTIONS,
  MatomoContext,
  PAGES,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  usePageTrackingWithTitle,
  useWindowSizeListener,
} from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ExportRequestsButton, MyRequestTable, RequestSearchBox } from '../../components';
import { POLL_INTERVAL } from '../../config/constants';
import { SortOrderObject } from '../../models/sort';

//import RequestStatusTab from '../../components/request-status-tab/request-status-tab';
/* eslint-disable-next-line */
export interface MyExistingSitePageProps {
  update: boolean;
}

export const MyExistingSitePage = (props: MyExistingSitePageProps) => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { conversationalQnAEngaged, results } = useContext(SearchContext);
  const [query, setQuery] = useState('');
  const { appName } = useEnvironment();
  const [requestStatus, setRequestStatus] = useState(RequestStatusType.APPROVED);
  const [count, setCount] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [pageVariable, setPageVariable] = useState(0);

  const defaultSort = (s?: RequestStatusType) => {
    const sort = s || requestStatus;
    return sort === RequestStatusType.APPROVED
      ? new SortOrderObject().toggleSort(SortField.CREATEDON)
      : new SortOrderObject().toggleSort(SortField.REVIEWEDON);
  };

  const [sortObject, setSortObject] = useState(defaultSort());

  const variables = {
    type: PageType.IGD_EXISTING_SITES,
    query: '*' + query + '*' || DEFAULT_SEARCH_QUERY,
    appName,
    sortOrder: sortObject.toSortOrder(),
    requestStatus,
    count,
    page: pageNo - 1,
    start: 0,
  };
  const { data, loading, refetch, stopPolling, startPolling, error } = useGetCrawlRequestsQuery({
    variables,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    // console.log("call useeffect")
    localStorage.removeItem('Existing_sites_count');
    if (props.update) {
      setRequestStatus(RequestStatusType.APPROVED);
    }
  }, [props.update, refetch]);

  const isMobile = useWindowSizeListener();
  const pageOptionsCount = Math.min(Math.ceil((data?.getContentOnboarding?.resultCount ?? 0) / 10), isMobile ? 2 : 5);

  const refreshStatus = (s: RequestStatusType) => {
    setRequestStatus(s);
    setPageNo(1);
    setSortObject(defaultSort(s));
    restartPolling({ ...variables, requestStatus: s, page: 0, sortOrder: defaultSort(s).toSortOrder() });
  };

  // localStorage.setItem("Existing_sites_count",JSON.stringify(v.count))
  const restartPolling = (v: IGetCrawlRequestsQueryVariablesCopy) => {
    // console.(log("page changed",v.page)
    // console.log("inside polling")
    // setPageVariable(v.page?v.page:0)
    const pageVariable = v.page ? v.page : 0;
    const contVariable = v.count ? v.count : 10;
    localStorage.setItem('Existing_sites_count', JSON.stringify(pageVariable * contVariable));
    stopPolling();
    refetch(v);
    startPolling(POLL_INTERVAL);
  };

  const onCountChange = (c: number) => {
    // localStorage.removeItem("Existing_sites_count")
    setCount(c);
    setPageNo(1);
    restartPolling({ ...variables, page: 0, count: c });
  };

  usePageTrackingWithTitle(PAGES.ALL_REQUESTS);

  return (
    <Row noGutters className="px-3">
      <Col xs="12" className="mb-2 d-flex align-items-center justify-content-between pt-4">
        <h1 className="d-flex flex-column">
          <span className={classNames('mb-1', { 'fs-16': isMobile })}>
            {data?.getContentOnboarding?.resultCount
              ? ` All Onboarded Sites (${data?.getContentOnboarding?.resultCount})`
              : 'All Onboarded Sites'}
          </span>
          <small className={classNames('text-grey', { 'fs-14': isMobile })}>
            View all the onboarded sites on intelligent search platform.
          </small>
        </h1>
        <ExportRequestsButton pageType={PageType.IGD_EXISTING_SITES} />
      </Col>

      <Col xs="12" lg="3" className="mb-2">
        <RequestSearchBox
          placeholder="Search for Data Source, Site URL, Space Name"
          query={query}
          onSearch={setQuery}
          isMobile={isMobile}
        />
      </Col>
      <Col
        xs="12"
        lg={{ span: 6, offset: 3 }}
        className="d-flex align-items-center justify-content-between justify-content-lg-end mb-2"
      >
        {/* <RequestStatusTab
          className="mr-lg-2"
          loading={loading}
          status={requestStatus}
          onChange={refreshStatus}
          resultCount={data?.getContentOnboarding?.resultCount}
        /> */}
        {isMobile ? '' : <ResultsPerPageDropdown className="py-2" option={count} onChange={onCountChange} />}
      </Col>

      <Col xs="12">
        <>
          <MyRequestTable
            data={data?.getContentOnboarding?.requests ?? []}
            loading={loading}
            status={requestStatus}
            sort={sortObject}
            setSort={setSortObject}
          />
          <Paginator
            results={results}
            itemsPerPage={count}
            onPageSelect={p => {
              setPageNo(p);
              restartPolling({ ...variables, page: p - 1 });
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.IGD_PAGINATE);
            }}
            pageNo={pageNo}
            pageOptionsCount={pageOptionsCount}
            totalItems={data?.getContentOnboarding?.resultCount ?? 0}
            conversationalQnAEngaged={conversationalQnAEngaged}
          />
        </>
      </Col>
    </Row>
  );
};

export default MyExistingSitePage;
