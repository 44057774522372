import { IDataAsset } from '@amgen/core';
import { AppContext, FacetContext, FacetsPanelContext, getZoom, SearchContext, useAltHotKey } from '@amgen/shared';
import { faFilter, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Button, Col, Nav, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';

import './facets-panel.scss';

import { HIERARCHAL_CONFIG } from '../../config/hierarchal-filters';
import FilterCategoryDropdown from '../filter-category-dropdown/filter-category-dropdown';
import { PivotFacetContainer } from '../pivot-facet-provider';
import SaveConfigButton from '../save-config-button/save-config-button';
import { useFilterCategoryOperations } from './hooks';

const FacetDropdown = lazy(() => import('../facet-dropdown/facet-dropdown'));

export interface FacetsPanelProps {
  excludeCount?: boolean;
  isMobile?: boolean;
}

export const FacetsPanel: React.FC<FacetsPanelProps> = props => {
  const { appliedFilters, setAppliedFilters, facetCategories } = useContext(FacetContext);
  const { attributeDisplayNames, defaultFacetCategories } = useContext(AppContext);
  const { selectedFilterCategories, isNotConfigurable, currentSelectedKeys } = useContext(FacetsPanelContext);
  const { isExpandable, setExpandPanel, loading } = useContext(SearchContext);
  const { toggleFilterCategory, toggleCurrentSelectedKeys } = useFilterCategoryOperations();
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState('');
  const isAccordianStyle = true;
  const zoomLevel = getZoom();
  //condition added for 150 percent resolution to show more width
  // const filterPanelWidth = zoomLevel === 1.5 || props.isMobile ? '12' : '11';
  const filterPanelWidth = zoomLevel === 1.5 || props.isMobile ? '12' : '10';
  useEffect(() => {
    setExpandPanel?.(false);
  }, []);
  //Alt + c to clear all filters
  useAltHotKey(() => setAppliedFilters(appliedFilters.clear()), 'c');
  useAltHotKey(() => setExpandPanel?.(true), 'q');
  useAltHotKey(() => setExpandPanel?.(false), 'w');

  const replaceDocNavPrefix = (input: (keyof IDataAsset)[] | Set<keyof IDataAsset>): (keyof IDataAsset)[] => {
    const array = Array.isArray(input) ? input : Array.from(input);
    return array.map(item => (item === 'doc_nav_prefix' ? 'content_source' : item));
  };
  const updatedFacetCategories = replaceDocNavPrefix(facetCategories);
  const updatedSelectedFilterCategories = replaceDocNavPrefix(selectedFilterCategories);
  const updatedDefaultCategoriesSet = new Set<keyof IDataAsset>(
    replaceDocNavPrefix(Array.from(defaultFacetCategories))
  );
  const categoriesArray = isNotConfigurable ? updatedFacetCategories : updatedSelectedFilterCategories;

  const sortedCategories = Array.from(categoriesArray)
    .map(category => ({
      key: category,
      name: attributeDisplayNames[category],
      isDefault: updatedDefaultCategoriesSet.has(category), // Mark if it's a default category
    }))
    .sort((a, b) => {
      // Ensure default categories appear first
      if (a.isDefault && !b.isDefault) return -1;
      if (!a.isDefault && b.isDefault) return 1;

      // Ensure content_source and doc_nav_prefix are at the top
      if (a.key === 'content_source' || a.key === 'doc_nav_prefix') return -1;
      if (b.key === 'content_source' || b.key === 'doc_nav_prefix') return 1;

      // Compare names
      return a.name.localeCompare(b.name);
    });

  return (
    <Row className={classnames({ '': props.isMobile })}>
      <Col xs={filterPanelWidth} className="d-flex justify-content-between">
        <Nav.Link className="shadow-sm border-top-primary m-0 py-1 h2">
          <FontAwesomeIcon className="mt-1 mr-1" icon={faFilter} />
          Filters
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={'filter-info-icon'} className="tooltip-width">
                Only filters that return valid search results will be displayed
              </Tooltip>
            }
          >
            <FontAwesomeIcon className="mt-1 ml-1 mb-2 fs-14" icon={faInfoCircle} />
          </OverlayTrigger>
        </Nav.Link>

        {isExpandable && !props.isMobile ? (
          <Button
            variant="link"
            className="py-0 px-1 pb-2"
            onClick={() => setExpandPanel?.(false)}
            title="Click to hide the Filter panel (Alt + w)"
          >
            {/* <FontAwesomeIcon className={loading ? '' : 'left-arrow-icon'} icon={faAngleDoubleLeft} size="lg" /> */}
            <img src={'assets/images/advacne-filter-close-logo.svg'} width="15px" />
          </Button>
        ) : null}
      </Col>

      {sortedCategories.map((category, i) => (
        <Col xs={filterPanelWidth} key={category.key}>
          <Suspense fallback={<Spinner animation="grow" size="sm" className="text-primary" />}>
            {Object.keys(HIERARCHAL_CONFIG).includes(category.key) ? (
              <PivotFacetContainer
                category={category.key}
                categoryTree={HIERARCHAL_CONFIG[category.key]!}
                categoryName={category.name}
                excludeCount={props.excludeCount}
                isExpand={[0, 1].includes(i) || currentSelectedKeys.has(category.key)}
              />
            ) : (
              <FacetDropdown
                category={category.key}
                categoryName={category.name}
                excludeCount={props.excludeCount}
                isExpand={[0, 1].includes(i) || currentSelectedKeys.has(category.key)}
                currentSelectedCategory={currentSelectedCategory}
              />
            )}
          </Suspense>
        </Col>
      ))}

      {!isNotConfigurable && (
        <Col xs={filterPanelWidth} className={isAccordianStyle ? 'my-3' : 'mb-3'}>
          <FilterCategoryDropdown
            selectedCategories={new Set(updatedSelectedFilterCategories)}
            onCategoryToggle={category => {
              toggleFilterCategory(category);
              toggleCurrentSelectedKeys(category);
              setCurrentSelectedCategory(category);
            }}
            categoryList={updatedFacetCategories}
          />
        </Col>
      )}

      <Col xs={filterPanelWidth} className="border-top pt-3">
        {!isNotConfigurable && <SaveConfigButton />}
      </Col>
    </Row>
  );
};

export default FacetsPanel;
