import { useAddBinaryFeedbackMutation } from '@amgen/api';
import { ApplicationName } from '@amgen/core';
import {
  CONVERSATIONAL_SEARCH_QnA_ACTIONS,
  MatomoContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
} from '@amgen/shared';
import { faThumbsDown as regularThumbsDown, faThumbsUp as regularThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsDown as solidThumbsDown, faThumbsUp as solidThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import './gen-ai-multi-turn.scss';

import GenAIBetaNote from './gen-ai-beta-note';
import GenerativeAIMultiLoader from './gen-ai-multi-turn-loader';
import GenAISummary from './gen-ai-summary';

const GenAIMultiTurn = () => {
  const {
    setUserPseudoId,
    setFollowUpQuery,
    followUpQuery,
    conversationalSearchData,
    userPseudoId,
    multiTurnAPICompleted,
    multiTurnConversationList,
    setMultiTurnAPICompleted,
    conversationalQnAEngaged,
    setConversationalQnAEngaged,
    finalFollowupQuery,
    setMultiTurnBinaryFeedback,
    selectedViewType,
  } = useContext(SearchContext);
  const [inputValue, setInputValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [addBinaryFeedback] = useAddBinaryFeedbackMutation();
  const multiTurnBinaryFeedbackStorage = localStorage.getItem('multiTurnBinaryFeedback');
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleClickEvent = () => {
    if (!conversationalQnAEngaged) {
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.GEN_AI_CONVERSATIONAL_SEARCH,
        CONVERSATIONAL_SEARCH_QnA_ACTIONS.CONVERSATIONAL_SEARCH_QnA_ACCORDIAN_CLICKS
      );
      setConversationalQnAEngaged(true);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setFollowUpQuery('');
    if (inputValue?.trim() === '') {
      // Do not proceed with form submission if followUpQuery is empty
      return;
    }

    setFollowUpQuery(inputValue);
    setMultiTurnAPICompleted(false);
    handleClickEvent();
    if (userPseudoId === '' && conversationalSearchData?.answerSearch?.userPseudoId) {
      setUserPseudoId(conversationalSearchData?.answerSearch?.userPseudoId);
    }
    setInputValue('');
  };

  return (
    <>
      <GenAIBetaNote />
      <div
        className={
          selectedViewType === ResultViewType.Table
            ? isExpanded
              ? 'col-xs-12 ml-4 mb-3 position-relative'
              : 'col-xs-12 ml-4 mb-3 multi-turn-show-less-container position-relative'
            : 'col-xs-12 ml-4'
        }
        id="data-container"
      >
        {multiTurnConversationList.length > 0 &&
          multiTurnConversationList?.map((item, index) => (
            <div key={index}>
              {index !== 0 ? (
                <div className="follow-up-query-container pr-2">
                  <div className="follow-up-query-align">{item.term}</div>
                </div>
              ) : (
                <></>
              )}

              <div
                className={
                  selectedViewType === ResultViewType.Table
                    ? isExpanded
                      ? multiTurnConversationList.length > 1
                        ? 'py-3 mx-3 mt-3 mb-5 summary-text'
                        : 'py-3 mx-3 my-4 summary-text'
                      : multiTurnConversationList.length > 1
                      ? 'py-3 mt-3 mb-5 mx-3 summary-text show-less-summary'
                      : 'py-3 my-4 mx-3 summary-text show-less-summary'
                    : multiTurnConversationList.length > 1
                    ? 'py-3 mt-3 mb-5 summary-text'
                    : 'py-3 my-4 summary-text'
                }
              >
                <GenAISummary item={item} />
                {index === multiTurnConversationList.length - 1 &&
                  item.citations.length > 0 &&
                  !(!multiTurnAPICompleted && multiTurnConversationList.length > 0 && followUpQuery !== '') && (
                    <div className="d-flex  px-3 py-2">
                      <div className="ask-followup-container d-flex justify-content-between">
                        <form onSubmit={handleSubmit} style={{ width: '100%' }} autoComplete="off">
                          <InputGroup>
                            <Form.Control
                              placeholder="Ask a follow up"
                              aria-label="FollowUp"
                              value={inputValue}
                              onChange={handleInputChange}
                              style={{ boxShadow: 'none', border: 'none' }}
                              id="follow-up-gen-ai"
                            />
                          </InputGroup>
                        </form>
                        <button
                          type="submit"
                          className={
                            !inputValue?.trim()
                              ? 'ask-followup-enter mr-2 disabled-follow-up'
                              : 'ask-followup-enter mr-2'
                          }
                          onClick={handleSubmit}
                          disabled={!inputValue?.trim()}
                        >
                          <svg focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 3v18l20-9L2 3zm2 11l9-2-9-2V6.09L17.13 12 4 17.91V14z"></path>
                          </svg>
                        </button>
                      </div>

                      <div className="d-flex align-items-center ml-4">
                        <FontAwesomeIcon
                          icon={multiTurnBinaryFeedbackStorage === 'positive' ? solidThumbsUp : regularThumbsUp}
                          className="fs-24 mb-2 mr-3 cursor-pointer feedback-multi-turn"
                          onClick={() => {
                            if (multiTurnBinaryFeedbackStorage !== 'positive') {
                              setMultiTurnBinaryFeedback('positive');
                              localStorage.setItem('multiTurnBinaryFeedback', 'positive');
                              addBinaryFeedback({
                                variables: {
                                  feedback: {
                                    applicationName: appName,
                                    assetId: '',
                                    binaryRating: 1,
                                    searchTerm: finalFollowupQuery !== '' ? finalFollowupQuery : item?.term,
                                    type: 'CONVERSATION_FEEDBACK',
                                  },
                                },
                              })
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                .then(() => {})
                                .catch(e => console.log(e));
                            }
                          }}
                        />
                        <FontAwesomeIcon
                          icon={multiTurnBinaryFeedbackStorage === 'negative' ? solidThumbsDown : regularThumbsDown}
                          className="fs-24 mb-2 cursor-pointer feedback-multi-turn"
                          onClick={() => {
                            if (multiTurnBinaryFeedbackStorage !== 'negative') {
                              setMultiTurnBinaryFeedback('negative');
                              localStorage.setItem('multiTurnBinaryFeedback', 'negative');
                              addBinaryFeedback({
                                variables: {
                                  feedback: {
                                    applicationName: appName,
                                    assetId: '',
                                    binaryRating: -1,
                                    searchTerm: finalFollowupQuery !== '' ? finalFollowupQuery : item?.term,
                                    type: 'CONVERSATION_FEEDBACK',
                                  },
                                },
                              })
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                .then(() => {})
                                .catch(e => console.log(e));
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ))}
        {selectedViewType === ResultViewType.Table &&
        !(!multiTurnAPICompleted && multiTurnConversationList.length > 0 && followUpQuery !== '') ? (
          isExpanded ? (
            <div className={'col-xs-12  multi-turn-transparent-show-less-btn'}>
              <button
                className={' multi-turn-show-btn'}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                Show Less &#9650;
              </button>
            </div>
          ) : (
            <div
              className={
                followUpQuery === ''
                  ? 'col-xs-12  multi-turn-transparent-btn'
                  : 'col-xs-12 multi-turn-transparent-btn-follow-up'
              }
            >
              <button
                className={' multi-turn-show-btn'}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                Show More &#9660;
              </button>
            </div>
          )
        ) : (
          <></>
        )}
      </div>

      {multiTurnConversationList.length === 0 && <GenerativeAIMultiLoader />}
    </>
  );
};

export default GenAIMultiTurn;
