import { DataSource } from '@amgen/core';

export const EEA_DATA_SOURCES: DataSource[] = [
  DataSource.CDOCS,
  DataSource.CONFLUENCE,
  DataSource.SHAREPOINT,
  DataSource.SMARTSHEET,
  DataSource.TWITTER,
  DataSource.AMGEN_RIM,
  DataSource.CORTELLIS,
  DataSource.PUBMED,
  DataSource.CLINICAL_TRIALS,
];

export const INTERNAL_SOURCES: DataSource[] = [
  DataSource.CDOCS,
  DataSource.CONFLUENCE,
  DataSource.SHAREPOINT,
  DataSource.SMARTSHEET,
  DataSource.AMGEN_RIM,
  DataSource.KM_ASSET,
  DataSource.ETMF,
  DataSource.ServiceNow,
  DataSource.MY_AMGEN,
];

export const READ_MORE_SOURCES: DataSource[] = [DataSource.MY_AMGEN];
