import { IAssetAttribute, useEnvironment } from '@amgen/core';
import { AppContext, FacetContext, PreferenceContext } from '@amgen/shared';
import { toggle } from '@amgen/utils';
import React, { useContext, useEffect, useState } from 'react';

import { Setting } from '../setting/setting';

// import './export-setitng.scss';

/* eslint-disable-next-line */
export interface ExportSetting {}

export const ExportSetting = (props: ExportSetting) => {
  const { appName } = useEnvironment();
  const { selectedExportFacets } = useContext(PreferenceContext);
  const { attributeDisplayNames } = useContext(AppContext);
  const [selectedFilters, setSelectedFilters] = useState(selectedExportFacets);
  const { facetCategories } = useContext(FacetContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  const LEGAL_EXPORT_FIELDS: Set<IAssetAttribute> = new Set([
    'confidentiality_expiry_date',
    'amgen_current_law_contacts',
    'law_external_contacts',
    'contract_type',
    'notes',
    'contract_reference_number',
    'contract_protocol',
    'contract_summary_terms',
    'amgen_paralegal',
    'amgen_attorney',
    'contract_amgen_company',
    'contract_ac_contact',
    'url',
    'title',
  ]);

  // const ABUSER_LEGAL_EXPORT_FIELDS: Set<IAssetAttribute> = new Set([
  //   'title',
  //   'url',
  //   'tags',
  //   'start_date',
  //   'product',
  //   'file_type_normalized',
  //   'drug_product',
  //   'document_effective_date',
  //   'document_approved_date',
  //   'doc_owner_s',
  //   'doc_nav_prefix',
  //   'doc_last_modified',
  //   'author_s',
  // ]);
  const ABUSER_EXPORT_FIELDS: Set<IAssetAttribute> = new Set(['title', 'url']);

  const exportFields =
    (abTestingGroup && currentCustomsource !== 'Contract Search') || appName === 'OpsKM' || appName === 'Catalyst'
      ? Array.from(new Set([...facetCategories, ...ABUSER_EXPORT_FIELDS]))
      : Array.from(new Set([...facetCategories, ...LEGAL_EXPORT_FIELDS]));

  return (
    <Setting>
      <Setting.Body
        options={Object.fromEntries(
          exportFields
            ?.sort((a, b) => attributeDisplayNames[a].localeCompare(attributeDisplayNames[b]))
            ?.map(key => [key, attributeDisplayNames[key]])
        )}
        selectedValues={selectedFilters}
        onOptionToggle={option =>
          setSelectedFilters(filters => toggle(filters, option as IAssetAttribute, f => f === option))
        }
        heading="Export Filter(s)"
      />
    </Setting>
  );
};

export default ExportSetting;
