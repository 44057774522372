import { Direction, SortField } from '@amgen/core';
import { AppContext, FacetContext, SearchContext } from '@amgen/shared';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';

import './top-search-filters.scss';

export interface TopChildFiltersProps {
  selectedParentFilter: string;
  parent?: string;
  topFilterData?: any;
}

enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
}

export function TopChildFilters(props: TopChildFiltersProps) {
  const internalSources = ['All', 'MyAmgen', 'CDOCS', 'Amgen RIM', 'eTMF', 'Ops KM Assets', 'SharePoint', 'ServiceNow'];
  const externalSources = ['All', 'ClinicalTrials.gov', 'Cortellis', 'PubMed'];
  const allSources = Array.from(new Set([...internalSources, ...externalSources]));
  const [activeKey, setActiveKey] = useState('All');
  //const { data } = useLazyTreeFacetQuery('content_source');
  const data = props.topFilterData;
  const { appliedFilters, setAppliedFilters, isCustomFilterSearch } = useContext(FacetContext);
  const { setPageNo, setSortOption, setSortOrder, setCustomSourceActive, isCustomSourceActive } = useContext(
    AppContext
  );

  const { setSelectedTopFilter, onClearAllFilters, setAllSelected, selectedTopFilter } = useContext(SearchContext);
  const selectedSources: string[] = [];
  const duplicateArray: string[] = [];
  const [sliderLimit, setSliderLimit] = useState(8);
  const [currentIndex, setCurrentIndex] = useState(0);
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  Object.entries(appliedFilters.filters!) // giving array of array
    //category is key of array
    //.filter(([category, _]) => !['prefix_location', 'prefix_class'].includes(category))
    .filter(([category, _]) => ['doc_nav_prefix'].includes(category))

    .map(([category, values]) =>
      [...values!].map(v => {
        const splitedValue = v.split('/')?.[1];
        // if (splitedValue) {
        //   duplicateArray.push(splitedValue);
        // }
        duplicateArray.push(splitedValue);
        return selectedSources.push(v.split('/').slice(-1)[0]);
      })
    );
  const allEqual = duplicateArray.every(v => v === duplicateArray[0]);

  const calculateActiveKey = () => {
    if (selectedSources?.length === 1) {
      const selectedItem = filters.includes(selectedSources[0]) ? selectedSources[0] : duplicateArray[0];
      return selectedItem;
    } else if (selectedSources?.length > 1 && allEqual && filters.includes(duplicateArray[0])) {
      return duplicateArray[0];
    } else {
      return 'All';
    }
  };
  let filters: any[] = [];

  let results: any[] = [];

  results = data.filter(source => source?.count ?? 0 > 0).map(ele => ele.value?.split('/').slice(-1)[0]);
  results.push('All');

  if (results.length > 0) {
    filters = ['All'].concat(results);
  }

  // explicit click on child top filter
  const setData = selectedKey => {
    setCustomSourceActive(false);
    setSortOption(SortField.RELEVANCY);
    setSortOrder(Direction.ASC);
    setCurrentIndex(currentIndex);
    appliedFilters.removeAll('doc_nav_prefix');
    setActiveKey(selectedKey || 'All');
    setPageNo(1);

    selectedKey !== 'All'
      ? setAppliedFilters(appliedFilters.add(`0/${selectedKey}`, 'doc_nav_prefix'))
      : onClearAllFilters();

    // prevent removing filters on selecting "all tab"

    // selectedKey !== 'All' && setAppliedFilters(appliedFilters.add(`0/${selectedKey}`, 'doc_nav_prefix'));

    setSelectedTopFilter(selectedKey);
    if (props.selectedParentFilter === 'Internal' || props.selectedParentFilter === 'All') {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  useEffect(() => {
    if (!isCustomFilterSearch) {
      setActiveKey('All');
    }
    if (props.selectedParentFilter === 'Internal' || props.selectedParentFilter === 'All') {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [props.selectedParentFilter]);

  useEffect(() => {
    const filterObj = appliedFilters?.filters ? appliedFilters.filters : {};
    if (
      (props.selectedParentFilter === 'Internal' && appName === 'EEA') ||
      (props.selectedParentFilter === 'All' && appName === 'EEA')
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedTopFilter, props.selectedParentFilter, setAllSelected, appliedFilters]);

  useEffect(() => {
    const calculateActiveKeyStored = calculateActiveKey();
    setActiveKey(calculateActiveKeyStored);
    setSelectedTopFilter(calculateActiveKeyStored);
    sessionStorage.setItem('isLegal', calculateActiveKeyStored === 'Contract Search' ? 'true' : 'false' || 'false');
  }, [setSelectedTopFilter, appliedFilters]);

  useEffect(() => {
    //To keep slider in the second page
    if (allSources.indexOf(activeKey) > 7 && allSources.indexOf(activeKey) <= 9) {
      setCurrentIndex(2);
      setSliderLimit(10);
    } else if (allSources.indexOf(activeKey) >= 9) {
      setCurrentIndex(4);
      setSliderLimit(12);
    }
  }, [activeKey]);

  return (
    <Row id="top-filters" className="no-gutters row w-91 child-filters">
      {props.selectedParentFilter === 'Internal' ? (
        internalSources.map((source, index) => {
          return results.length > 1 && results.includes(source) ? (
            <div key={index} className={classNames('top-section-category classActive ml-3', index === 0 ? '' : 'ml-3')}>
              <div
                className={classNames(
                  activeKey === source ? 'active-child filter-badge cursor-pointer' : ' cursor-pointer filter-badge',
                  props.parent ? 'fs-10' : ''
                )}
                onClick={() => setData(source)}
                title={
                  source === 'SharePoint'
                    ? 'This displays data from selected SharePoint sites which is currently onboarded. Look for more details in Content Source filter'
                    : source === 'Amgen RIM'
                    ? 'Our Records and Information Management system that governs the creation, maintenance, distribution, protection, storage, retention, and destruction of records.'
                    : source === 'CDOCS'
                    ? 'Internal document management system which contains record, operational, supporting, governance, legacy audit trail, template, and quality manual documentation.'
                    : source === 'eTMF'
                    ? 'A system to support the electronic Trial Master File (eTMF) business process, the clinical trial management process, and regulatory filing and submission processes.'
                    : source === 'MyAmgen'
                    ? 'Our internal employee portal for company news, announcements, programs, policies, key information, and resources.'
                    : source === 'Ops KM Assets'
                    ? 'Operations knowledge asset repository used to capture and share knowledge'
                    : source
                }
              >
                {' '}
                {source}{' '}
              </div>
            </div>
          ) : (
            <></>
          );
        })
      ) : props.selectedParentFilter === 'External' ? (
        externalSources.map((source, index) => {
          return results.length > 1 && results.includes(source) ? (
            <div
              key={index}
              className={classNames(
                'top-section-category classActive ml-3',
                index === 0 ? '' : 'ml-3',
                props.parent ? 'fs-10' : ''
              )}
            >
              <div
                className={classNames(
                  activeKey === source ? 'active-child filter-badge cursor-pointer' : ' cursor-pointer filter-badge'
                )}
                onClick={() => setData(source)}
                title={source}
              >
                {' '}
                {source}{' '}
              </div>
            </div>
          ) : (
            <></>
          );
        })
      ) : props.selectedParentFilter === 'All' && results.length > 6 ? (
        <>
          {currentIndex !== 0 ? (
            <div>
              <FontAwesomeIcon
                icon={faChevronLeft}
                title="Click to see more sources"
                onClick={() => {
                  setCurrentIndex(currentIndex - 2);
                  setSliderLimit(sliderLimit - 2);
                }}
                className="left-arrow cursor-pointer"
              />
            </div>
          ) : (
            ''
          )}
          {allSources.slice(currentIndex, sliderLimit).map((source, index) => {
            return results.length > 0 && results.includes(source) ? (
              <div>
                <div
                  key={index}
                  className={classNames(
                    'top-section-category classActive cursor-pointer ml-3',
                    index === 0 ? '' : 'ml-3',
                    props.parent ? 'fs-10' : ''
                  )}
                >
                  <div
                    className={classNames(activeKey === source ? 'active-child filter-badge' : ' filter-badge')}
                    onClick={() => setData(source)}
                    title={
                      source === 'SharePoint'
                        ? 'This displays data from selected SharePoint sites which is currently onboarded. Look for more details in Content Source filter'
                        : source === 'Amgen RIM'
                        ? 'Our Records and Information Management system that governs the creation, maintenance, distribution, protection, storage, retention, and destruction of records.'
                        : source === 'CDOCS'
                        ? 'Internal document management system which contains record, operational, supporting, governance, legacy audit trail, template, and quality manual documentation.'
                        : source === 'eTMF'
                        ? 'A system to support the electronic Trial Master File (eTMF) business process, the clinical trial management process, and regulatory filing and submission processes.'
                        : source === 'MyAmgen'
                        ? 'Our internal employee portal for company news, announcements, programs, policies, key information, and resources.'
                        : source === 'Ops KM Assets'
                        ? 'Operations knowledge asset repository used to capture and share knowledge'
                        : source
                    }
                  >
                    {' '}
                    {source === 'All' ? 'All Sources' : source}
                  </div>
                </div>
              </div>
            ) : (
              <> </>
            );
          })}
          {sliderLimit < allSources.length ? (
            <div>
              <FontAwesomeIcon
                icon={faChevronRight}
                title="Click to see more sources"
                onClick={() => {
                  setCurrentIndex(currentIndex + 2);
                  setSliderLimit(sliderLimit + 2);
                }}
                className="right-arrow cursor-pointer"
              />
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        allSources.map((source, index) => {
          return results.length > 1 && results.includes(source) ? (
            <div>
              <div
                key={index}
                className={classNames(
                  'top-section-category classActive cursor-pointer ml-3',
                  index === 0 ? '' : 'ml-3',
                  props.parent ? 'fs-10' : ''
                )}
              >
                <div
                  className={classNames(activeKey === source ? 'active-child filter-badge' : ' filter-badge')}
                  onClick={() => setData(source)}
                  title={
                    source === 'SharePoint'
                      ? 'This displays data from selected SharePoint sites which is currently onboarded. Look for more details in Content Source filter'
                      : source === 'Amgen RIM'
                      ? 'Our Records and Information Management system that governs the creation, maintenance, distribution, protection, storage, retention, and destruction of records.'
                      : source === 'CDOCS'
                      ? 'Internal document management system which contains record, operational, supporting, governance, legacy audit trail, template, and quality manual documentation.'
                      : source === 'eTMF'
                      ? 'A system to support the electronic Trial Master File (eTMF) business process, the clinical trial management process, and regulatory filing and submission processes.'
                      : source === 'MyAmgen'
                      ? 'Our internal employee portal for company news, announcements, programs, policies, key information, and resources.'
                      : source === 'Ops KM Assets'
                      ? 'Operations knowledge asset repository used to capture and share knowledge'
                      : source
                  }
                >
                  {' '}
                  {source === 'All' ? 'All Sources' : source}
                </div>
              </div>
            </div>
          ) : (
            <></>
          );
        })
      )}
    </Row>
  );
}

export default TopChildFilters;
