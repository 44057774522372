import React from 'react';

import './gen-ai-multi-turn.scss';

const GenerativeAIMultiLoader = () => {
  const shimerAnimatedBars = [...Array(4)].map((_, i) => (
    <div className={`shine-multi-turn box-shine-multi-turn-${i + 1}`} />
  ));
  return (
    <div className="genrative-ai-loading col ml-4 pb-3 pt-3 mb-3 ">
      <div className="d-flex">
        <div className="circle circle-half mr-2" />
        <div className="font-italic font-weight-bold animated-note">Generating...</div>
      </div>

      {shimerAnimatedBars}
    </div>
  );
};

export default GenerativeAIMultiLoader;
