import { useFacetQuery } from '@amgen/api';
import { DataSource, Direction, IAssetAttribute, IDataAsset, SortField, useEnvironment } from '@amgen/core';
import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import { AppContext, FacetContext, FiltersObject, useCleanSearchQuery, useWindowSizeListener } from '@amgen/shared';
import { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export function useAssetTitleUrl(asset: IDataAsset) {
  const title = asset?.title || asset?.survey_name || asset?.id || 'Unknown Title';

  const { path } = useRouteMatch();
  const { appName } = useEnvironment();
  const url = asset?.url;
  const detailUrl = `${path}document/${encodeURIComponent(encodeURIComponent(asset?.id || ''))}`;
  const target = url ? '_blank' : '_self';
  const documentIconContentSources = [
    DataSource.CDOCS.toString(),
    DataSource.ETMF.toString(),
    // DataSource.ServiceNow.toString(),
    DataSource.CORTELLIS.toString(),
    DataSource.AMGEN_RIM.toString(),
  ];
  const isMobile = useWindowSizeListener();
  //Checking file extension through id to show the doc icon
  const fileExtension = asset?.id?.split('.').splice(-1)[0];
  const showDocumentIcon =
    !isMobile &&
    (documentIconContentSources.indexOf(asset?.content_source || '') !== -1 ||
      ((asset?.content_source === DataSource.SHAREPOINT.toString() ||
        asset?.content_source === DataSource.KM_ASSET.toString()) &&
        ((asset?.file_type_normalized && asset?.file_type_normalized !== 'aspx') ||
          (!asset?.file_type_normalized && fileExtension !== 'aspx'))))
      ? true
      : false;

  const isMsgType = fileExtension === 'msg' ? true : false;
  const map = useMemo(
    () =>
      new Map<
        DataSource,
        {
          title: string;
          url?: string;
          target?: string;
          detailUrl?: string;
          showDocumentIcon?: boolean;
          isMsgType?: boolean;
        }
      >([
        [DataSource.ALIGN, { title, url, detailUrl, target }],
        [
          DataSource.ALIGN_SURVEY,
          { title: path.substr(1) === 'surveys' ? `${title}-${asset.account_name}` : title, url, detailUrl, target },
        ],
        [DataSource.AMGEN_RIM, { title, url, detailUrl, target, showDocumentIcon }],
        [DataSource.BOX, { title, url, detailUrl, target }],
        [DataSource.C3, { title, url, detailUrl, target }],
        [DataSource.CDOCS, { title, url: asset?.steady_state_url ?? url, detailUrl, target, showDocumentIcon }],
        [DataSource.CONFLUENCE, { title, url, detailUrl, target }],
        [DataSource.CORTELLIS, { title, url, detailUrl, target, showDocumentIcon }],
        [DataSource.ELN, { title, url, detailUrl, target }],
        [DataSource.IDBS_GXP, { title, url, detailUrl, target }],
        [DataSource.IDBS_NON_GXP, { title, url, detailUrl, target }],
        [DataSource.IRPC, { title, url: asset?.mirlink ?? url, detailUrl, target }],
        [DataSource.MY_AMGEN, { title, url, detailUrl, target, showDocumentIcon }],
        [DataSource.PERKIN_ELMER, { title, url, detailUrl, target }],
        [DataSource.PUBMED, { title, url, detailUrl, target, showDocumentIcon }],
        [
          appName !== 'KMI' ? DataSource.SHAREPOINT : DataSource.SPO,
          { title, url, detailUrl, target, showDocumentIcon, isMsgType },
        ],
        [DataSource.SMARTSHEET, { title, url, detailUrl, target }],
        [DataSource.TWITTER, { title, url, detailUrl, target }],
        [DataSource.WATERS_ACO, { title, target, detailUrl }],
        [DataSource.WATERS_AML, { title, target, detailUrl }],
        [DataSource.WATERS_ARI, { title, target, detailUrl }],
        [DataSource.WATERS_ELN_ACO, { title, target, detailUrl }],
        [DataSource.WATERS_ELN_AML, { title, target, detailUrl }],
        [DataSource.WATERS_ELN_ARI, { title, target, detailUrl }],
        [DataSource.CLINICAL_TRIALS, { title, url, detailUrl, target, showDocumentIcon }],
        [DataSource.KM_ASSET, { title, url, detailUrl, target, showDocumentIcon }],
        [DataSource.ETMF, { title, url, detailUrl, target, showDocumentIcon }],
        // [DataSource.ServiceNow, { title, url, detailUrl, target, showDocumentIcon }],
      ]),
    [asset?.account_name, asset?.mirlink, asset?.steady_state_url, detailUrl, path, title, url, target]
  );

  if (!asset?.content_source) {
    return {
      title: asset?.title,
      url: asset?.url,
      detailUrl: detailUrl,
      target: target,
      showDocumentIcon: showDocumentIcon,
      isMsgType: isMsgType,
    };
    // throw new Error(`Invalid Data: content_source is empty for asset: ${asset.id}`);
  }

  const config = map.get(asset?.content_source!);
  if (!config) {
    return {
      title: asset?.title,
      url: asset?.url,
      detailUrl: detailUrl,
      target: target,
      showDocumentIcon: showDocumentIcon,
      isMsgType: isMsgType,
    };

    // throw new Error(`Unconfigured DataSource: Add title/url configuration for "${asset.content_source}"`);
  }
  // console.log("config",config)

  return config;
}

export function useAssetAttachmentField(asset: IDataAsset) {
  const attachmentField: IAssetAttribute = 'parent_object_id';
  const { appName } = useEnvironment();

  const map = useMemo(
    () =>
      new Map<DataSource, { attachmentField: IAssetAttribute }>([
        [DataSource.ALIGN_SURVEY, { attachmentField }],
        [DataSource.ALIGN, { attachmentField }],
        [DataSource.AMGEN_RIM, { attachmentField }],
        [DataSource.BOX, { attachmentField }],
        [DataSource.C3, { attachmentField }],
        [DataSource.CDOCS, { attachmentField }],
        [DataSource.CONFLUENCE, { attachmentField }],
        [DataSource.CORTELLIS, { attachmentField }],
        [DataSource.ELN, { attachmentField: 'experiment_id' }],
        [DataSource.IDBS_GXP, { attachmentField }],
        [DataSource.IDBS_NON_GXP, { attachmentField }],
        [DataSource.IRPC, { attachmentField }],
        [DataSource.MY_AMGEN, { attachmentField }],
        [DataSource.PERKIN_ELMER, { attachmentField }],
        [DataSource.PUBMED, { attachmentField }],
        [appName !== 'KMI' ? DataSource.SHAREPOINT : DataSource.SPO, { attachmentField }],
        [DataSource.SMARTSHEET, { attachmentField }],
        [DataSource.TWITTER, { attachmentField }],
        [DataSource.CLINICAL_TRIALS, { attachmentField }],
        [DataSource.WATERS_ACO, { attachmentField: 'experiment_id' }],
        [DataSource.WATERS_AML, { attachmentField: 'experiment_id' }],
        [DataSource.WATERS_ARI, { attachmentField: 'experiment_id' }],
        [DataSource.WATERS_ELN_ACO, { attachmentField: 'experiment_id' }],
        [DataSource.WATERS_ELN_AML, { attachmentField: 'experiment_id' }],
        [DataSource.WATERS_ELN_ARI, { attachmentField: 'experiment_id' }],
        [DataSource.CLINICAL_TRIALS, { attachmentField }],
        [DataSource.KM_ASSET, { attachmentField }],
        [DataSource.ETMF, { attachmentField }],
        // [DataSource.ServiceNow, { attachmentField }],
      ]),
    [attachmentField]
  );

  if (!asset?.content_source) {
    //throw new Error(`Invalid Data: content_source is empty for asset: ${asset.id}`);
    return { attachmentField };
  }

  const config = map.get(asset?.content_source);
  if (!config) {
    return { attachmentField };
    //throw new Error(`Unconfigured DataSource: Add title/url configuration for "${asset.content_source}"`);
  }

  return config;
}

export function useEEALandingPageDataSource() {
  const { setPageNo } = useContext(AppContext);
  const { appliedFilterCategories, setAppliedFilters, setAppliedFilterCategories } = useContext(FacetContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // TODO: need to fix typing for state
    const newState = location.state as any;
    if (newState?.value) {
      setPageNo(1);
      const filtersObj = new FiltersObject();
      if (Array.isArray(newState.value)) {
        if (newState.groupName) {
          newState.value.forEach((v: string) => filtersObj.add(v, 'doc_nav_prefix'));
        } else {
          newState.value.forEach((v: string) => filtersObj.add(`0/${v}`, 'doc_nav_prefix'));
        }
      } else {
        filtersObj.add(`0/${newState?.value}`, 'doc_nav_prefix');
      }
      setAppliedFilters(filtersObj);
      setAppliedFilterCategories(new Set([...appliedFilterCategories, 'doc_nav_prefix']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
}

export function useKMILandingPageDataSource() {
  const { setPageNo } = useContext(AppContext);
  const { appliedFilterCategories, setAppliedFilters, setAppliedFilterCategories } = useContext(FacetContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // TODO: need to fix typing for state
    const newState = location.state as any;
    if (newState?.value && newState?.category) {
      setPageNo(1);
      setAppliedFilters(new FiltersObject().add(newState?.value, 'content_source'));
      setAppliedFilterCategories(new Set([...appliedFilterCategories, 'content_source']));
      // this clears of the state after adding up the filters
      // history.replace({ ...location, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
}

export function useClearFiltersOnNavFromBookmark() {
  const { setPageNo, setSortOption, setSortOrder, setCustomSourceActive, setSelectedCustomSourceName } = useContext(
    AppContext
  );
  const { appliedFilters, setAppliedFilters } = useContext(FacetContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // TODO: need to fix typing for state
    const newState = location.state as any;
    if (newState?.clearFilters) {
      setPageNo(1);
      setAppliedFilters(appliedFilters.clear());
      setSortOption(SortField.RELEVANCY);
      setSortOrder(Direction.ASC);
      setCustomSourceActive(false);
      setSelectedCustomSourceName('Custom Sources');
      // this clears of the state after clearing  up the filters after coming from bookmarks
      history.replace({ ...location, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
}

enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
}

export function useSearchBoxFilterQuery(
  selectedFilter?: IAssetAttribute | null,
  searchTerm?: string,
  filtersObj?: FiltersObject
) {
  const filters = useAppliedFiltersWithPersistentValues(filtersObj);
  const cleanQuery = useCleanSearchQuery();
  //const { currentSource } = useContext(SearchContext);
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const { data, loading } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters,
      fields: [{ name: selectedFilter!, facetLimit: 20, searchTerm: searchTerm ?? '', minCount: 0 }],
      excludeCount: false,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
      isLegalContractsSearch: currentCustomsource === 'Contract Search' ? true : false,
      //sourceType: SOURCE_TYPE.type[currentSource],
    },
    fetchPolicy: 'network-only',
    skip: !selectedFilter,
  });

  return { data: selectedFilter ? data : null, loading };
}
