import { useSavePreferenceMutation } from '@amgen/api';
import { IAssetAttribute, SettingsPreferenceType, useEnvironment } from '@amgen/core';
import {
  AppContext,
  MatomoContext,
  PreferenceContext,
  SAVING_CONFIGURATIONS_ACTIONS,
  SEARCH_APP_FEATURE_LIST,
  useToast,
} from '@amgen/shared';
import { toggle } from '@amgen/utils';
import React, { useContext, useEffect, useState } from 'react';

import './filter-category-preference.scss';

import { Setting } from '../setting/setting';

/* eslint-disable-next-line */
export interface FilterCategoryPreferenceProps {}

export const FilterCategoryPreference = (props: FilterCategoryPreferenceProps) => {
  const { selectedFacetConfig, refetchPreferences } = useContext(PreferenceContext);
  const { attributeDisplayNames, searchFacetCategories, defaultFacetCategories } = useContext(AppContext);
  // const [selectedFilters, setSelectedFilters] = useState([...(defaultFacetCategories as any)]);
  // const defaultFacetCategoriesCopy = [...defaultFacetCategories];
  const [selectedFilters, setSelectedFilters] = useState([...defaultFacetCategories, ...selectedFacetConfig]);
  // not seeing the selected filters , an bug is there
  const toast = useToast();
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [updateDefaultFilterConfig, { loading }] = useSavePreferenceMutation();
  const [error, setError] = useState('');
  const { appName } = useEnvironment();
  //  console.log(searchFacetCategories.sort((a, b) => a[0].localeCompare(b[1])))
  const searchFacetCategoriesCopy = [...searchFacetCategories];
  return (
    <Setting>
      {/* <Setting.Header heading="Default Filter(s)" infoContent="Default Filter(s) for the Search page" /> */}

      <Setting.Body
        options={Object.fromEntries(
          searchFacetCategoriesCopy
            .sort((a, b) => attributeDisplayNames[a].localeCompare(attributeDisplayNames[b]))
            .map(key => [key, attributeDisplayNames[key]])
        )}
        selectedValues={selectedFilters && selectedFilters}
        onOptionToggle={option =>
          setSelectedFilters(filters => toggle(filters, option as IAssetAttribute, f => f === option))
        }
        heading="Default Filter(s)"
      />
      <Setting.Footer
        isLoading={loading}
        onSave={() => {
          if (selectedFilters.length === 0) {
            toast.danger({
              message: 'Unable to save configuration. At least one default filter must be selected',
              id: 'default-filter-category-toast',
              timeout: 5000,
            });
          } else {
            updateDefaultFilterConfig({
              variables: { appName, values: selectedFilters, type: SettingsPreferenceType.FACET_CATEGORY },
            })
              .then(() => {
                toast.success({ message: 'Saved successfully!', id: 'default-filter-category-toast', timeout: 5000 });
                refetchPreferences();
              })
              .catch(err =>
                toast.danger({
                  message:
                    err?.graphQLErrors?.[0]?.exception?.message ??
                    'Unable to save preferences. Please try again later.',
                  id: 'default-filter-category-toast',
                  timeout: 5000,
                })
              );
            trackApplicationFeature(
              SEARCH_APP_FEATURE_LIST.SAVING_CONFIGURATIONS,
              SAVING_CONFIGURATIONS_ACTIONS.SAVE_DEFAULT_FILTERS
            );
          }
        }}
      />
    </Setting>
  );
};

export default FilterCategoryPreference;
