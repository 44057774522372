/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

import '../redirectUrl.scss';

const SearchUrlBreadCrumbs = ({ docSolrAssetData, toggleUrlLength }) => {
  const extractLastValue = docSolrAssetData?.doc_nav_prefix?.slice(-1); //pick last value of doc  nav prefix
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const splitedValues = extractLastValue?.toString().split('/');
  const removedFirstValue = splitedValues?.splice(0, 1); //removing first value of doc nav prefix array since its a num
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  //to limit the array & show ellipse
  const limitSplitValue = splitedValues?.slice(0, 2);

  //split number from tring for id logic
  const splitAlphaNum = docSolrAssetData?.id?.split('');
  const tempArr: number[] = [];
  splitAlphaNum?.forEach(num => {
    const storeNum = parseInt(num);
    if (!isNaN(storeNum)) {
      tempArr.push(storeNum);
    }
  });
  const extractid = tempArr.join('');

  //limit the string length
  const filename = docSolrAssetData?.source_filename || '';
  const ellipsesFilename =
    filename?.length > 41 ? docSolrAssetData?.source_filename?.slice(0, 41) + '...' : docSolrAssetData?.source_filename;

  //title on hover
  const hoverTitle =
    docSolrAssetData?.content_source === 'PubMed' ||
    docSolrAssetData?.content_source === 'Cortellis' ||
    docSolrAssetData?.content_source === 'ClinicalTrials.gov' ||
    docSolrAssetData?.content_source === 'ServiceNow'
      ? splitedValues?.join(' / ') + ' / ' + extractid
      : docSolrAssetData?.content_source === 'Amgen RIM' ||
        docSolrAssetData?.content_source === 'CDOCS' ||
        docSolrAssetData?.content_source === 'eTMF'
      ? splitedValues?.join(' / ') + ' / ' + (docSolrAssetData?.document_number || '')
      : docSolrAssetData?.content_source === 'SharePoint' || docSolrAssetData?.content_source === 'Ops KM Assets'
      ? splitedValues?.join(' / ') + ' / ' + (docSolrAssetData?.source_filename || '')
      : '';

  return (
    <>
      <Breadcrumb className="redirect-urls" title={hoverTitle}>
        {/* loop doc nav prefix content sources hierarchy */}
        {limitSplitValue &&
          limitSplitValue?.map((val, index) => {
            return val === 'MyAmgen' ? (
              <p title={docSolrAssetData?.url} className="custom-ellipse ellipses">
                {toggleUrlLength}
              </p>
            ) : (
              <>
                <Breadcrumb.Item href="#" className={abTestingGroup && index === 0 ? 'font-weight-bold' : ''}>
                  {val}
                </Breadcrumb.Item>
              </>
            );
          })}

        {/* conditional suggested end started */}
        {docSolrAssetData?.content_source === 'PubMed' ||
        docSolrAssetData?.content_source === 'Cortellis' ||
        docSolrAssetData?.content_source === 'ClinicalTrials.gov' ||
        docSolrAssetData?.content_source === 'ServiceNow' ? (
          <Breadcrumb.Item href="#">{extractid}</Breadcrumb.Item>
        ) : docSolrAssetData?.content_source === 'Amgen RIM' ||
          docSolrAssetData?.content_source === 'CDOCS' ||
          docSolrAssetData?.content_source === 'eTMF' ? (
          <>
            {!docSolrAssetData?.document_number || docSolrAssetData?.document_number === null ? (
              <>{''}</>
            ) : (
              <>
                <Breadcrumb.Item href="#" className="custom-ellipse ellipses">
                  ...
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">{docSolrAssetData?.document_number}</Breadcrumb.Item>
              </>
            )}
          </>
        ) : docSolrAssetData?.content_source === 'SharePoint' ||
          docSolrAssetData?.content_source === 'Ops KM Assets' ? (
          <>
            {!docSolrAssetData?.source_filename || docSolrAssetData?.source_filename === null ? (
              <>{''}</>
            ) : (
              <>
                <Breadcrumb.Item href="#" className="custom-ellipse ellipses">
                  ...
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#" className="filename-ellipse ellipses">
                  {ellipsesFilename}
                </Breadcrumb.Item>
              </>
            )}
          </>
        ) : (
          ''
        )}
        {docSolrAssetData.pageNo !== '' && docSolrAssetData.pageNo !== null && abTestingGroup ? (
          <Breadcrumb.Item href="#">{`(Page: ${docSolrAssetData.pageNo})`}</Breadcrumb.Item>
        ) : (
          <></>
        )}
      </Breadcrumb>
    </>
  );
};

export default SearchUrlBreadCrumbs;
