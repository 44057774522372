import { useFacetQuery } from '@amgen/api';
import { ApplicationName, IAssetAttribute, IFacetValue } from '@amgen/core';
import {
  AppContext,
  FacetContext,
  FacetsPanelContext,
  SearchContext,
  useCleanSearchQuery,
  useSearchQueryValue,
  useToast,
} from '@amgen/shared';
import { format, subYears } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import './facet-dropdown.scss';

import { useAppliedFiltersWithPersistentValues } from '../../persistent-filter-values';
import { useFilterCategoryOperations, useFilterOperations } from '../facets-panel/hooks';
import { useFacetComponents } from '../use-facet-components';

export interface FacetDropdownProps {
  category: IAssetAttribute;
  categoryName: string | undefined;
  excludeCount?: boolean;
  isExpand?: boolean;
  currentSelectedCategory?: string;
}

export const FacetDropdown: React.FC<FacetDropdownProps> = props => {
  const { onCategoryClear } = useFilterCategoryOperations();
  const { handleFilterToggle } = useFilterOperations();
  const { appliedFilters } = useContext(FacetContext);
  const { selectedFilters } = useContext(FacetsPanelContext);
  const { currentSource, isCustomSourceActive } = useContext(AppContext);
  const { DropdownItems, FacetContainer } = useFacetComponents(props.category);
  const query = useSearchQueryValue();
  const cleanQuery = useCleanSearchQuery();
  const filters = useAppliedFiltersWithPersistentValues();
  const [hasNext, setHasNext] = useState(true);
  const [hasValue, setHasValue] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [facetValues, setFacetValues] = useState<IFacetValue[]>([]);
  const [count, setCount] = useState(1);
  const toast = useToast();
  const [page, setPage] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState(filters);
  const { expandPanel, setExpandPanel, selectedTopFilter } = useContext(SearchContext);
  let duplicateArray: string[] = [];
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  //
  //.filter(([category, _]) => !['prefix_location', 'prefix_class'].includes(category))
  const categoryArray: string[] = [];
  Object.keys(appliedFilters.filters!).forEach(function (key, index) {
    categoryArray.push(key);
  });

  Object.entries(appliedFilters.filters!)
    .filter(([category, _]) => categoryArray.includes(category))

    .map(([category, values]) =>
      [...values!].map(v => {
        if (category === 'doc_nav_prefix') {
          duplicateArray.push(v.split('/')?.[1]);
        }

        return duplicateArray;
      })
    );

  duplicateArray = [...new Set(duplicateArray)];

  useEffect(() => {
    setPage(0);
    setHasNext(true);
  }, [appliedFilters, query, count]);

  const categoryItems = selectedFilters.filters?.[props.category] ?? new Set<string>();
  const filtersKey = filters.map(item => item.split(' :')[0]);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const attendanceFields = [
    {
      isNumericRangeFacet: true,
      facetRange: 'page',
      facetRangeStart: '0',
      facetRangeGap: '100',
      facetLimit: -1,
    },
  ];

  const facetFields = [
    {
      name: props.category,
      facetLimit: 10,
      page,
      searchTerm,
      minCount: count,
    },
  ];

  //const PassArray =[sharepointArray+" OR " + filters[0]]
  const modifiedFilters = filters;
  const before2yearsDate = format(subYears(new Date(), 2), 'yyyy-MM-dd') + 'T00:00:00Z';

  if (
    (duplicateArray.length === 0 ||
      (duplicateArray.length === 1 && !duplicateArray.includes('SharePoint')) ||
      duplicateArray.length > 1) &&
    !cleanQuery.includes('id:') &&
    !isCustomSourceActive &&
    applicationName === ApplicationName.EEA &&
    !abTestingGroup
  ) {
    modifiedFilters.push(
      `( *:* AND -content_source:SharePoint) OR (content_source:SharePoint AND source_filename:(*aspx OR *ASPX OR *pdf OR *PDF  OR  *doc  OR  *DOC  OR  *docx  OR  *DOCX  OR  *docm  OR  *DOCM  OR  *pptm  OR  *PPTM  OR  *ppt  OR  *PPT  OR  *pptx  OR  *PPTX  OR  *Pdf)  AND doc_last_modified:[${
        before2yearsDate + ' TO NOW'
      }])`
    );
  }

  const { loading } = useFacetQuery({
    variables: {
      solrHandler: applicationName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      // filters: showMore ? updatedFilters : modifiedFilters,
      filters: modifiedFilters,
      fields: props.category === 'page' ? attendanceFields : facetFields,
      excludeCount: !!props.excludeCount,
      appName: applicationName,
      userType: sessionStorage.getItem('employeeType') || '',
      sourceType: currentSource,
      isLegalContractsSearch: currentCustomsource === 'Contract Search' ? true : false,
    },
    skip: !expandPanel || (abTestingGroup && props?.categoryName?.includes('Date')),
    onCompleted: data => {
      if (data?.facet?.[0]?.values) {
        //To show toaster message if a user selects a filter and it has zero values
        if (data?.facet?.[0]?.values.length <= 0) {
          if (props.category === props.currentSelectedCategory && !hasValue) {
            toast.danger({
              message: 'Your selected filter will not be shown in the filter panel if no valid search results match',
              timeout: 10000,
            });
          }
        }
        // show only unique values under the main filter selected , to avoid duplicate valuees in the filter panel
        const facetValuesCombinedData = [...facetValues, ...data?.facet?.[0].values];
        const facetValuesUniqueData = facetValuesCombinedData.filter((obj, index) => {
          return index === facetValuesCombinedData.findIndex(o => obj.value === o.value);
        });
        page ? setFacetValues(facetValuesUniqueData) : setFacetValues(data?.facet?.[0].values);
        setHasNext(true);
        //for the edge case when the API gets called for next pages and returns 0 results
        //here we set that the facet category has some values atleast to ignore the 0 results return for next pages
        setHasValue(true);
      }
    },
  });

  // const { data: treeData, loading: load } = useLazyTreeFacetQuery(props.category, props.prefix);

  const loadMoreItems = () => {
    if (loading) return null;
    setPage(page + 1);

    //prevent facet api call on window scroll

    // window.addEventListener('scroll', e => {
    //   console.log('setPage');
    //   setPage(page + 1);
    // });

    return Promise.resolve();
  };

  const handleSearch = (term: string) => {
    setPage(0);
    setHasNext(true);
    setSearchTerm(term);
  };

  const ShowMoreActions = category => {
    const filtersForShowMoreAction = filters.filter(item => {
      return item.split(' :')[0] !== category;
    });

    setUpdatedFilters(filtersForShowMoreAction);
    setShowMore(true);
    setCount(0);
  };

  const showLessActions = () => {
    setUpdatedFilters(filters);
    setShowMore(false);
    setCount(1);
  };
  return facetValues.length > 0 || props?.categoryName?.includes('Date') ? (
    <FacetContainer
      title={`${props.categoryName} (${categoryItems.size})`}
      onValueToggle={value => handleFilterToggle(props.category, value)}
      onClear={() => onCategoryClear(props.category)}
      isLoading={loading}
      isExpand={props.isExpand}
    >
      <DropdownItems
        facetValues={facetValues}
        category={props.category}
        hasNextPage={hasNext}
        isNextPageLoading={loading}
        loadNextPage={loadMoreItems}
        onSearch={handleSearch}
        showMore={showMore}
      />

      {facetValues.length !== 0 &&
        // !props.categoryName?.includes('Content Source') &&
        !props.categoryName?.includes('Date') &&
        filtersKey.includes(props.category) &&
        (count > 0 ? (
          <span
            className="pl-4 float-right text-primary fs-13 m-2"
            onClick={() => {
              ShowMoreActions(props.category);
            }}
          >
            + Show More
          </span>
        ) : (
          <span
            className="pl-4 float-right text-primary fs-13 m-2"
            onClick={() => {
              showLessActions();
            }}
          >
            - Show Less
          </span>
        ))}
    </FacetContainer>
  ) : facetValues.length <= 0 && searchTerm ? (
    <FacetContainer
      title={`${props.categoryName} (${categoryItems.size})`}
      onValueToggle={value => handleFilterToggle(props.category, value)}
      onClear={() => onCategoryClear(props.category)}
      isLoading={loading}
      isExpand={props.isExpand}
    >
      <DropdownItems
        facetValues={facetValues}
        category={props.category}
        hasNextPage={hasNext}
        isNextPageLoading={loading}
        loadNextPage={loadMoreItems}
        onSearch={handleSearch}
      />

      {facetValues.length !== 0 &&
        // !props.categoryName?.includes('Content Source') &&
        !props.categoryName?.includes('Date') &&
        filtersKey.includes(props.category) &&
        (count > 0 ? (
          <span
            className="pl-4 float-right text-primary fs-13 m-2"
            onClick={() => {
              ShowMoreActions(props.category);
            }}
          >
            + Show More
          </span>
        ) : (
          <span
            className="pl-4 float-right text-primary fs-13 m-2"
            onClick={() => {
              showLessActions();
            }}
          >
            - Show Less
          </span>
        ))}
    </FacetContainer>
  ) : null;
};

export default FacetDropdown;
