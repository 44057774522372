import { IApplicationAccessDetails, IGlobalApplicationAccessDetails, IUserInfo, useEnvironment } from '@amgen/core';
import { useOktaAuth } from '@okta/okta-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { EnvironmentContext } from '../../../../core/src/lib/environment';
import { useGetApplicationAccessDetails, useGetAuthorizationPageQuery, useGetTemporaryS3FileAccess } from './access-api';
import { useAddConsentAgreementMutation, useValidateConsentAgreementQuery } from './consent-agreement';

export const CONSENT_GROUP = 'MainConsentMay2021';

//[todo] remove this after url-params is moved from shared lib
enum UTMParams {
  CAMPAIGN = 'pk_campaign',
  SOURCE = 'pk_source',
  MEDIUM = 'pk_medium',
  FILTER = 'pk_filter',
  FUNCTION = 'pk_function',
  ASSET_TYPE = 'pk_asset_type',
  SITE = 'pk_spo_site',
}

enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
}

export function useAuth(authPageFlag?: boolean) {
  const authentication = useAuthentication();
  const authorization = useAuthorization(authPageFlag);
  const authorizedPages = useAuthorizedPages();
  return { ...authentication, ...authorization, ...authorizedPages };
}

export function getDowntimeStatus() {
  const getStatusData = FetchConfigFileData();
  return { ...getStatusData };
}

function useAuthentication() {
  const { authState, oktaAuth } = useOktaAuth();

  return {
    isAuthenticated: authState.isAuthenticated,
    isPending: authState.isPending,
    error: authState.error,
  };
}

export function useLogoutApp() {
  const { authState, oktaAuth } = useOktaAuth();
  const { api } = useEnvironment();

  return {
    isAuthenticated: authState.isAuthenticated,
    logout: async () => {
      return await oktaAuth.signOut({ postLogoutRedirectUri: api.postLogoutRedirectUri });
    },
    isPending: authState.isPending,
    error: authState.error,
  };
}

function useAuthorization(authPageFlag?: boolean) {
  const location = useLocation();
  const startTime = new Date().getTime();
  const envContext = useContext(EnvironmentContext);
  const keyword = new URLSearchParams(location.search);
  const query = keyword.get('q');

  const { firstName, fullName, emailId } = useUsersNames();
  const { appName, externalAppName, setTrackingAppName } = useEnvironment();
  const DUMMY_VALUE = 'NOT_AVAILABLE';
  const params = new URLSearchParams(decodeURIComponent(location.search));

  if (params.get(UTMParams.CAMPAIGN)?.toLocaleLowerCase() === 'widget' && params.get(UTMParams.SOURCE)) {
    if (params.get(UTMParams.SOURCE) !== 'MyAmgen') {
      sessionStorage.setItem('externalAppName', params.get(UTMParams.SOURCE)!);
      sessionStorage.setItem('appName', params.get(UTMParams.SOURCE)!);
    }
    setTrackingAppName(params.get(UTMParams.SOURCE)!);
    sessionStorage.setItem('trackingAppName', params.get(UTMParams.SOURCE)!);
  }

  if (params.get(UTMParams.CAMPAIGN)?.toLocaleLowerCase() === 'widget' && params.get(UTMParams.FILTER)) {
    sessionStorage.setItem('externalFilter', params.get(UTMParams.FILTER)!);
  } else if (
    params.get(UTMParams.CAMPAIGN)?.toLocaleLowerCase() === 'widget' &&
    !params.get(UTMParams.FILTER) &&
    !params.get(UTMParams.ASSET_TYPE) &&
    params.get(UTMParams.SOURCE) === 'OpsKM'
  ) {
    sessionStorage.setItem('externalFilter', 'All');
    sessionStorage.setItem('externalFunction', '');
    sessionStorage.setItem('externalAssetType', '');
    sessionStorage.setItem('externalSite', '');
  }

  if (params.get(UTMParams.CAMPAIGN)?.toLocaleLowerCase() === 'widget' && params.get(UTMParams.FUNCTION)) {
    sessionStorage.setItem('externalFunction', params.get(UTMParams.FUNCTION)!);
    if (params.get(UTMParams.ASSET_TYPE) !== null) {
      sessionStorage.setItem('externalFilter', 'Ops KM Assets');
    } else {
      if (params.get(UTMParams.FILTER) === null) {
        sessionStorage.setItem('externalFilter', 'All');
      } else {
        sessionStorage.setItem('externalFilter', params.get(UTMParams.FILTER)!);
      }
    }
  }

  if (params.get(UTMParams.CAMPAIGN)?.toLocaleLowerCase() === 'widget' && params.get(UTMParams.ASSET_TYPE)) {
    sessionStorage.setItem('externalAssetType', params.get(UTMParams.ASSET_TYPE)!);
    if (params.get(UTMParams.ASSET_TYPE) !== null) {
      sessionStorage.setItem('externalFilter', 'Ops KM Assets');
    } else {
      if (params.get(UTMParams.FILTER) === null) {
        sessionStorage.setItem('externalFilter', 'All');
      } else {
        sessionStorage.setItem('externalFilter', params.get(UTMParams.FILTER)!);
      }
    }
  }

  if (params.get(UTMParams.CAMPAIGN)?.toLocaleLowerCase() === 'widget' && params.get(UTMParams.SITE)) {
    sessionStorage.setItem('externalSite', params.get(UTMParams.SITE)!);
    //sessionStorage.setItem('externalFilter', 'SharePoint');
  }

  if (!params.get(UTMParams.CAMPAIGN)) {
    sessionStorage.setItem('externalFilter', '');
    sessionStorage.setItem('externalAssetType', '');
    sessionStorage.setItem('externalFunction', '');
  }

  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : window.document.title === 'KMI Search'
    ? ApplicationName.KMI
    : ApplicationName.EEA;

  const getFilterListVariables = useMemo(
    () => ({
      appName: applicationName,
    }),
    [applicationName]
  );
  const { data, loading: getAccessDetailsLoader } = useGetApplicationAccessDetails({
    variables: getFilterListVariables,
    onCompleted: () => {
      if (authPageFlag) {
        localStorage.setItem('User Access API', JSON.stringify(new Date().getTime() - startTime));
        const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
        sessionStorage.setItem('abTestingGroup', JSON.stringify(data?.getApplicationAccessDetails?.abTestingGroup));
        envContext?.setEnvGraphqlUrl(
          data?.getApplicationAccessDetails?.abTestingGroup ? `${initialGraphAPIUrl}/v2` : initialGraphAPIUrl
        );
        sessionStorage.setItem(
          'employeeType',
          data?.getApplicationAccessDetails?.employeeType === 'Staff' ? 'FTE' : 'CW'
        );
      }
      // if (data?.getApplicationAccessDetails?.abTestingGroup !== null) {
      //   sessionStorage.setItem('abTestingGroup', JSON.stringify(data?.getApplicationAccessDetails?.abTestingGroup));
      // }
    },
  });

  useEffect(() => {
    let AccessDetailsTimeOutID;
    if (getAccessDetailsLoader) {
      AccessDetailsTimeOutID = setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
    return () => {
      clearTimeout(AccessDetailsTimeOutID);
      sessionStorage.setItem('current-source-selected', '');
      if (query === null) {
        sessionStorage.setItem('currentSearchTerm', '*:*');
      }
    };
  }, [getAccessDetailsLoader]);

  const result = useMemo(() => {
    const { applicationDetails, ...accessInfo } =
      (data?.getApplicationAccessDetails as IApplicationAccessDetails) ?? {};
    const ad =
      [
        ...(applicationDetails ? (applicationDetails as IGlobalApplicationAccessDetails[]) : []),
        ...(externalAppName
          ? [
              {
                applicationName: externalAppName,
                accessible: true,
                isExternal: true,
              } as IGlobalApplicationAccessDetails,
            ]
          : []),
      ] ?? [];
    return {
      isAuthorized: !!ad?.find(app => app.applicationName === appName)?.accessible,
      appDetails: ad,
      getAccessDetailsLoader,
      user: {
        ...accessInfo,
        firstName,
        fullName,
        costCenter: accessInfo.costCenter ?? DUMMY_VALUE,
        subFunctionalArea: accessInfo.subFunctionalArea ?? DUMMY_VALUE,
        functionalArea: accessInfo.functionalArea ?? DUMMY_VALUE,
        adGroups: accessInfo.adGroups ?? DUMMY_VALUE,
        emailId,
      } as IUserInfo,
      externalAppName,
      appName,
    };
  }, [
    data?.getApplicationAccessDetails,
    externalAppName,
    getAccessDetailsLoader,
    firstName,
    fullName,
    emailId,
    appName,
  ]);
  sessionStorage.setItem('username', result.user.fullName);
  sessionStorage.setItem('costcenter', result.user.costCenter);
  return result;
}

function useAuthorizedPages() {
  //const { appName } = useEnvironment();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const { data, loading } = useGetAuthorizationPageQuery({ fetchPolicy: 'cache-first' });
  const authorizedTabs = data?.getAuthorizationDetails?.applications?.find(x => x.parent === appName)?.pages;

  console.log('appName', appName);

  const result = useMemo(() => {
    return { tabs: authorizedTabs, authLoading: loading };
  }, [authorizedTabs, loading]);
  return result;
}

export function useUsersNames() {
  const { authState, oktaAuth } = useOktaAuth();
  const [fullName, setFullName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [emailId, setEmailId] = useState<string>();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then(user => {
        setFullName(user?.name);
        setFirstName(user?.given_name);
        setEmailId(user?.email);
      });
    }
  }, [authState.isAuthenticated, oktaAuth]);

  return { fullName, firstName, emailId };
}

export function useConsentCheck() {
  //const { appName: applicationName } = useEnvironment();
  const [hasAgreedToConsent, setHasAgreedToConsent] = useState(true);
  // const applicationName = sessionStorage.getItem('appName') === 'OpsKM' ? ApplicationName.OpsKM : ApplicationName.EEA;
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  useValidateConsentAgreementQuery({
    variables: {
      applicationName,
    },
    onCompleted: d => {
      const flag = !!d.validateConsentAgreement;
      //const flag = true;
      setHasAgreedToConsent(flag);
    },
  });

  const [addConsentAgreement] = useAddConsentAgreementMutation();

  const handleAgreedConsent = () => {
    addConsentAgreement({ variables: { applicationName, consents: CONSENT_GROUP } }).then(resp => {
      const flag = resp.data?.addConsentAgreement?.status?.toUpperCase() === 'SUCCESS';
      setHasAgreedToConsent(flag);
    });
  };
  return { hasAgreedToConsent, handleAgreedConsent };
}

interface IDowntimeData {
  downtime_flag: boolean;
  downtime_message: string;
}

function FetchConfigFileData() {
  const [applicationStatus, setapplicationStatus] = useState<IDowntimeData>();
  const { data } = useGetTemporaryS3FileAccess({
    variables: {
      paths: [
        'https://s3.us-west-2.amazonaws.com/amgen-edf-cdl-ent-installers-misc-bkt/dev/ui-downtime-config/downtime_configs.json',
      ],
    },
    onCompleted: () => {
      const presignedUrl: any = data?.getTemporaryS3FileAccess?.[0]?.preSignedUrl;
      fetch(presignedUrl)
        .then(response => response.text())
        .then(s3Data => {
          setapplicationStatus(JSON.parse(s3Data));
        });
    },
    // fetchPolicy: 'network-only',
  });

  return { applicationStatus, setapplicationStatus };
}
