import {
  useGetFeatureCommentFacetQuery,
  useGetFeatureLikesFacetQuery,
  useGetRoadmapsQuery,
  useGetUserProfileFacetQuery,
} from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, IFacetValue, useEnvironment } from '@amgen/core';
import { SOLR_REFRESH_TIME } from '@amgen/utils';
import React, { useCallback, useMemo } from 'react';

import './roadmap-context-provider.scss';

import { IRoadmapState, RoadmapContext } from './roadmap-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RoadmapContextProviderProps {
  setBlockScroll: (value: boolean) => void;
  width?: number;
}

export const RoadmapContextProvider: React.FC<RoadmapContextProviderProps> = ({ children, ...props }) => {
  const { appName } = useEnvironment();
  const user = JSON.parse(sessionStorage.user);


  const { data, loading } = useGetRoadmapsQuery({ fetchPolicy: 'no-cache' });

  const {
    data: commentFacet,
    loading: commentCountLoading,
    refetch: refetchUserComments,
  } = useGetFeatureCommentFacetQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      appName,
      fields: [
        {
          name: 'parent_object_id',
          facetLimit: -1,
          page: 0,
          minCount: 1,
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  const { data: likeFacet, loading: likeCountLoading, refetch } = useGetFeatureLikesFacetQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      filters: ['type :ROADMAP_LIKE', 'active:true'],
      appName,
      fields: [
        {
          name: 'parent_object_id',
          facetLimit: -1,
          page: 0,
          minCount: 1,
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  const { data: userLikes, refetch: refetchUserLikes } = useGetUserProfileFacetQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      filters: [`user_id : ${user.username}`, 'type :ROADMAP_LIKE', 'active:true'],
      appName,
      fields: [
        {
          name: 'parent_object_id',
          facetLimit: -1,
          page: 0,
          minCount: 1,
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  const refetchUserMetrics = useCallback(() => {
    setTimeout(() => {
      refetchUserLikes();
      refetch();
    }, SOLR_REFRESH_TIME);
  }, [refetch, refetchUserLikes]);

  const covertFacetToMap = (values?: Pick<IFacetValue, 'value' | 'count'>[]) => {
    return new Map(values?.map(f => [f.value as string, f.count as number]));
  };

  // const quarters = [...new Set(data?.getRoadmaps?.map(x => x.features?.flatMap(f => f.startDate)).flat())];

  const providedValue = useMemo<IRoadmapState>(
    () => ({
      loading,
      likeCountLoading,
      featureIdLikeCountMap: covertFacetToMap(likeFacet?.facet?.[0].values),
      featureIdCommentCountMap: covertFacetToMap(commentFacet?.facet?.[0].values),
      featureIdUserLikesMap: covertFacetToMap(userLikes?.facet?.[0].values),
      commentCountLoading,
      refetchUserComments,
      refetchUserMetrics,
      roadmapThemes: data?.getRoadmaps ?? [],
      setBlockScroll: props.setBlockScroll,
      width: props.width,
    }),
    [
      loading,
      likeCountLoading,
      likeFacet,
      commentFacet,
      userLikes,
      commentCountLoading,
      refetchUserComments,
      refetchUserMetrics,
      data,
      props,
    ]
  );

  return <RoadmapContext.Provider value={providedValue}>{children}</RoadmapContext.Provider>;
};

export default RoadmapContextProvider;
