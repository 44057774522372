import { AssetContext } from '@amgen/shared';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useContext } from 'react';
import { Button, Row } from 'react-bootstrap';

import './body.scss';

export interface BodyProps {
  disableExpandToggle?: boolean;
}

export const Body: React.FC<BodyProps> = ({ children }) => {
  const { setToggleEnabled, isToggleEnabled, showReadMoreFlag } = useContext(AssetContext);
  return (
    <Row className="overflow-hidden asset-body">
      {children}
      {/* // This is hidden by default. Check css for `search-result-item` class. It applies `visibility: visible`
        // to `asset-expand-btn` on hover. It uses `visibility` instead of `display` as `visibility` is animatable
        // but `display` is not. `transition-delay` is used to add delay. */}
      {showReadMoreFlag && (
        <Button
          className="asset-expand-btn px-1 py-0 bg-white text-primary position-absolute invisible"
          style={{ bottom: '-1.2rem', left: '45%' }}
          variant="outline-primary"
          onClick={() => setToggleEnabled(!isToggleEnabled)}
        >
          <span className="px-2 small">Show {!isToggleEnabled ? 'more' : 'less'}</span>
          <FontAwesomeIcon
            className="mr-1"
            icon={faAngleDoubleDown}
            size="xs"
            transform={{ rotate: !isToggleEnabled ? -0 : 180 }}
          />
        </Button>
      )}
    </Row>
  );
};

export default Body;
