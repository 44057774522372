import { useChatbotConfigQuery } from '@amgen/api';
import { FeatureChatbot } from '@amgen/feature-chatbot';
import {
  AISERA_CHATBOT_ACTIONS,
  AppContext,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
} from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
/* eslint-disable-next-line */
export interface FeatureChatBotContainerProps {
  chatbotChannel: string;
  chatbotURL: string;
}

export function FeatureChatbotContainer(props: FeatureChatBotContainerProps) {
  const { searchQueryVariables } = useContext(SearchContext);
  const { setChatbotIntent } = useContext(AppContext);
  const { query } = searchQueryVariables;
  const chatbotChannelQuery = props?.chatbotChannel;
  const configData = useChatbotConfigQuery(query, chatbotChannelQuery);
  const [searchTerm, setSearchTerm] = useState('*:*');
  const [showChatBot, setShowChatBot] = useState(false);
  const { trackApplicationFeature, dimensions, trackCustomDimensions } = useContext(MatomoContext);
  console.log('chatbot called');
  useEffect(() => {
    const queryval = configData?.response !== null ? Object.keys(configData?.response).includes('query_term') : null;
    const invokeval =
      configData?.response !== null ? Object.keys(configData?.response).includes('invoke_chatbot') : null;
    if (configData?.response?.query_term) {
      setSearchTerm(configData?.response?.query_term);
      setShowChatBot(true);
    }
    if (!queryval && invokeval && searchTerm === '*:*') {
      setSearchTerm('*:*');
      setShowChatBot(true);
    }
    if (configData?.response?.invoke_chatbot === true && query !== '*:*' && query !== '') {
      setChatbotIntent(true);
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT, AISERA_CHATBOT_ACTIONS.CHATBOT_INVOKED);
      trackCustomDimensions(searchTerm, dimensions.CHATBOT_QUERIES);
    } else if (configData?.response?.invoke_chatbot === false || query === '*:*' || query === '') {
      setChatbotIntent(false);
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT, AISERA_CHATBOT_ACTIONS.CHATBOT_NOT_INVOKED);
    }
  }, [configData?.response?.query_term, configData?.response?.invoke_chatbot]);

  return (
    <FeatureChatbot
      showChatBot={showChatBot}
      searchTerm={searchTerm}
      chatbotURL={props.chatbotURL}
      configData={configData}
    />
  );
}

export default FeatureChatbotContainer;
