import React, { useContext, useEffect, useState } from 'react';

import './generative-ai.scss';

const GenerativeAILoader = () => {
  const shimerAnimatedBars = [...Array(4)].map((_, i) => <div className={`shine box-${i}`} />);
  return (
    <div className="generative-container col ml-2 pb-3 pt-3 mb-3 ">
      <div className="d-flex">
        {/* <div className="generate-loader mb-4 ml-2 mr-3" /> */}
        <div className="circle circle-half mr-2" />
        <div className="font-italic font-weight-bold animated-note">Generating...</div>
      </div>

      {shimerAnimatedBars}
    </div>
  );
};

export default GenerativeAILoader;
