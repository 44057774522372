import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { Button, Carousel, Modal } from 'react-bootstrap';

import './expand-button-modal.scss';

interface IbannerData {
  message_type: string;
  title: string;
  description: string;
}

/* eslint-disable-next-line */
export interface ExpandButtonModalProps {
  isExpandOpen: boolean;
  handleCloseBanner: () => void;
  onExpandBannerData: IbannerData[];
}
export const ExpandButtonModal = (props: ExpandButtonModalProps) => {
  useEffect(() => {
    if (props.isExpandOpen) {
      const carousalIndicatorCollection = document.getElementsByClassName('carousel-indicators');
      if (carousalIndicatorCollection) {
        for (let i = 0; i < carousalIndicatorCollection.length; ++i) {
          carousalIndicatorCollection[i].remove();
        }
        console.log('removed');
      }
    }
  }, []);

  return (
    <div>
      {props.isExpandOpen && (
        <div className="expand-modal-container">
          <Modal className="expand-modal" show={props.isExpandOpen} animation>
            <Carousel
              controls={props.onExpandBannerData?.length > 1}
              className="expand-carousal"
              interval={null}
              fade={true}
              nextIcon={
                <div aria-hidden="true" className="carousel-control-next-icon">
                  <img src="assets/images/carousel-next-icon.svg" alt="Next" />
                </div>
              }
              prevIcon={
                <div aria-hidden="true" className="carousel-control-prev-icon">
                  <img src="assets/images/carousel-prev-icon.svg" alt="Previous" />
                </div>
              }
            >
              {props.onExpandBannerData?.map(i => (
                <Carousel.Item>
                  <div className="modal-message-type">
                    <div
                      className={classNames(
                        'modal-message-type-indicator',
                        i.message_type === 'maintenance' ? 'expand-maintenance' : 'good'
                      )}
                    />

                    {i.message_type === 'maintenance' ? 'Alert(s)' : 'Announcement(s)'}
                  </div>
                  <Modal.Header>
                    <Modal.Title>
                      <div
                        className={classNames(
                          'modal-title-indicator',
                          i.message_type === 'maintenance' ? 'expand-maintenance' : 'good'
                        )}
                      />
                      {i.title}
                    </Modal.Title>
                  </Modal.Header>
                  <div className="border-line-partial"></div>
                  <Modal.Body>
                    <p
                      className="carousal-body-content"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(i.description),
                      }}
                    ></p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="minimize-button" variant="primary" onClick={() => props.handleCloseBanner()}>
                      {props.onExpandBannerData?.length > 1 ? 'Minimize' : 'Close'}
                    </Button>
                  </Modal.Footer>
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ExpandButtonModal;
