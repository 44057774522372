import { useEnvironment } from '@amgen/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Nav } from 'react-bootstrap';
import { NavLink, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import './base-settings-page.scss';

import { TABS, TABSINTEGRATION } from '../../config/tabs';
import { DataSourcePreference } from '../data-source-preference/data-source-preference';
import { ExportSetting } from '../export-setting/export-setting';
import { FilterCategoryPreference } from '../filter-category-preference/filter-category-preference';

/* eslint-disable-next-line */
export interface BaseSettingsPageProps {}

export const BaseSettingsPage: React.FC<BaseSettingsPageProps> = ({ children }) => {
  const { goBack } = useHistory();
  const { path, url } = useRouteMatch();
  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs);
  }

  const { appName } = useEnvironment();
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const settingsTab =
    appName === 'OpsKM' || appName === 'Catalyst' || appName === 'DVIEW' || currentCustomsource === 'Contract Search'
      ? TABSINTEGRATION
      : TABS;
  const authorizedTabs = settingsTab.filter(x => authTabs?.includes(x.id));
  // const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  // let tabsReplica;
  // if (abTestingGroup) {
  //   tabsReplica = TABS?.filter(x => x.title !== 'Export Settings');
  // } else {
  //   tabsReplica = [...TABS]
  // }
  // const [getUrl, setgetUrl] = useState(localStorage.getItem('current_new_url'));

  // const returnback=()=>{
  //   setgetUrl(localStorage.getItem("current_new_url"))
  // }

  return (
    <div
      className=" px-5 col-12
    "
    >
      <Col
        xs="12"
        className="mt-3 px-5
    "
      >
        <div className="d-flex justify-content-between">
          {/* <a href={getUrl ? getUrl : '/home'}> */}
          <Button variant="link" className="h2 ml-1 my-auto" onClick={goBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="pl-2 d-none d-sm-inline">Back</span>
          </Button>
          {/* </a> */}

          <Nav className="ag-navbar align-items-center justify-content-end" variant="tabs">
            {settingsTab.map(tab => (
              <Nav.Item className="text-center " key={tab.title}>
                <NavLink
                  exact
                  to={`${url}${tab.pathname}`}
                  className="nav-link h2 mb-0 align-items-center d-flex justify-content-center"
                  activeClassName="active"
                >
                  <FontAwesomeIcon icon={tab.icon} />
                  <span className="pl-2 d-none d-md-block">{tab.title}</span>
                </NavLink>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <Col xs="12" className="bg-white shadow-sm border border-top-0 mb-7 pb-7 px-10 static-height">
          {/* <Switch></Switch> */}
          {appName === 'OpsKM' ||
          appName === 'Catalyst' ||
          appName === 'DVIEW' ||
          currentCustomsource === 'Contract Search' ? (
            <Switch>
              <Route path={`${path}/export-settings`} component={ExportSetting}></Route>
              <Route path={`${path}`} component={FilterCategoryPreference}></Route>
            </Switch>
          ) : (
            <Switch>
              <Route path={`${path}/export-settings`} component={ExportSetting}></Route>
              <Route path={`${path}/filter-settings`} component={FilterCategoryPreference}></Route>
              <Route path={`${path}`} component={DataSourcePreference}></Route>
            </Switch>
          )}
        </Col>
      </Col>
    </div>
  );
};

export default BaseSettingsPage;
