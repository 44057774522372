import classNames from 'classnames';
import React, { useContext } from 'react';
import { Pagination } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import './next-page.scss';

import { useNextPage } from '../hooks';

/* eslint-disable-next-line */
export interface NextPageProps {
  results?: any[];
}

export const NextPage = (props: NextPageProps) => {
  const [canNextPage, handleNextPage] = useNextPage();
  const location = useLocation();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  return (
    <Pagination.Next
      disabled={
        canNextPage ||
        (abTestingGroup &&
          props.results &&
          props.results?.length <= 14 &&
          !location.pathname.includes('/data-requests'))
      }
      className={classNames({ 'disabled-cursor': canNextPage })}
      onClick={handleNextPage}
      title="Next Page"
    />
  );
};

export default NextPage;
