import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IFacetQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  fields?: Types.Maybe<
    Array<Types.Maybe<Types.IFacetFieldParametersInput>> | Types.Maybe<Types.IFacetFieldParametersInput>
  >;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  excludeCount: Types.Scalars['Boolean'];
  collectionName?: Types.Maybe<Types.Scalars['String']>;
  skipAuthorization?: Types.Maybe<Types.Scalars['Boolean']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
  sourceType?: Types.Maybe<Types.Scalars['String']>;
  isLegalContractsSearch?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IFacetQuery = {
  facet?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IFacetField, 'field'> & {
          values?: Types.Maybe<
            Array<Types.Maybe<Types.MakeOptional<Pick<Types.IFacetValue, 'value' | 'count'>, 'count'>>>
          >;
        }
      >
    >
  >;
};

export type IPivotFacetQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  fields?: Types.Maybe<
    Array<Types.Maybe<Types.IFacetFieldParametersInput>> | Types.Maybe<Types.IFacetFieldParametersInput>
  >;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  excludeCount: Types.Scalars['Boolean'];
  sourceType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IPivotFacetQuery = {
  facet?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IFacetField, 'field'> & {
          values?: Types.Maybe<
            Array<
              Types.Maybe<
                Types.MakeOptional<Pick<Types.IFacetValue, 'value' | 'count'>, 'count'> & {
                  subField?: Types.Maybe<
                    Pick<Types.IFacetField, 'field'> & {
                      values?: Types.Maybe<
                        Array<
                          Types.Maybe<
                            Types.MakeOptional<Pick<Types.IFacetValue, 'value' | 'count'>, 'count'> & {
                              subField?: Types.Maybe<
                                Pick<Types.IFacetField, 'field'> & {
                                  values?: Types.Maybe<
                                    Array<
                                      Types.Maybe<
                                        Types.MakeOptional<Pick<Types.IFacetValue, 'value' | 'count'>, 'count'>
                                      >
                                    >
                                  >;
                                }
                              >;
                            }
                          >
                        >
                      >;
                    }
                  >;
                }
              >
            >
          >;
        }
      >
    >
  >;
};

export const FacetDocument = gql`
  query Facet(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $fields: [FacetFieldParametersInput]
    $solrHandler: String
    $excludeCount: Boolean!
    $collectionName: String
    $skipAuthorization: Boolean
    $userType: String
    $sourceType: String
    $isLegalContractsSearch: Boolean = false
  ) {
    facet(
      applicationName: $appName
      query: $query
      filters: $filters
      fields: $fields
      solrHandler: $solrHandler
      collectionName: $collectionName
      skipAuthorization: $skipAuthorization
      userType: $userType
      sourceType: $sourceType
      isLegalContractsSearch: $isLegalContractsSearch
    ) {
      field
      values {
        value
        count @skip(if: $excludeCount)
        __typename
      }
      __typename
    }
  }
`;
export function useFacetQuery(baseOptions: Apollo.QueryHookOptions<IFacetQuery, IFacetQueryVariables>) {
  return Apollo.useQuery<IFacetQuery, IFacetQueryVariables>(FacetDocument, baseOptions);
}
export function useFacetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IFacetQuery, IFacetQueryVariables>) {
  return Apollo.useLazyQuery<IFacetQuery, IFacetQueryVariables>(FacetDocument, baseOptions);
}
export type FacetQueryHookResult = ReturnType<typeof useFacetQuery>;
export type FacetLazyQueryHookResult = ReturnType<typeof useFacetLazyQuery>;
export type FacetQueryResult = Apollo.QueryResult<IFacetQuery, IFacetQueryVariables>;
export const PivotFacetDocument = gql`
  query PivotFacet(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $fields: [FacetFieldParametersInput]
    $solrHandler: String = "/search-select"
    $excludeCount: Boolean!
    $sourceType: String
  ) {
    facet(
      applicationName: $appName
      query: $query
      filters: $filters
      fields: $fields
      solrHandler: $solrHandler
      sourceType: $sourceType
    ) {
      field
      values {
        value
        count @skip(if: $excludeCount)
        subField {
          field
          values {
            value
            count @skip(if: $excludeCount)
            subField {
              field
              values {
                value
                count @skip(if: $excludeCount)
              }
            }
          }
        }
      }
    }
  }
`;
export function usePivotFacetQuery(baseOptions: Apollo.QueryHookOptions<IPivotFacetQuery, IPivotFacetQueryVariables>) {
  return Apollo.useQuery<IPivotFacetQuery, IPivotFacetQueryVariables>(PivotFacetDocument, baseOptions);
}
export function usePivotFacetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPivotFacetQuery, IPivotFacetQueryVariables>
) {
  return Apollo.useLazyQuery<IPivotFacetQuery, IPivotFacetQueryVariables>(PivotFacetDocument, baseOptions);
}
export type PivotFacetQueryHookResult = ReturnType<typeof usePivotFacetQuery>;
export type PivotFacetLazyQueryHookResult = ReturnType<typeof usePivotFacetLazyQuery>;
export type PivotFacetQueryResult = Apollo.QueryResult<IPivotFacetQuery, IPivotFacetQueryVariables>;
