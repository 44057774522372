import { IFacetValue } from '@amgen/core';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Dropdown, FormCheck } from 'react-bootstrap';

import './facet-value.scss';

export interface NodeProps {
  facet: IFacetValue;
  isSelected: boolean;
  className?: string;
  eventKey?: string;
  displayValue?: string;
}

const Node = (props: NodeProps) => {
  // This fixes the bug if a child is selected followed by the parent, the parent is not marked as checked
  // in the ui even tough the state is updated correctly.
  const [checked, setChecked] = useState(props.isSelected);

  useEffect(() => {
    setTimeout(() => setChecked(props.isSelected), 0);
  }, [props.isSelected]);
  const isFacetCountPresent = (props.facet.count ?? 0) > 0;
  //condition added below for 150% resolution text
  const facetPanelText = isFacetCountPresent ? 'facet-panel-text' : '';
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  return (
    <Dropdown.Item
      className={classNames('d-flex justify-content-between align-items-center', props.className)}
      eventKey={props.eventKey ?? props.facet.value}
      title={props.displayValue ?? props.facet.value}
    >
      <FormCheck className="w-75">
        <FormCheck.Input type="checkbox" checked={checked} readOnly />
        <FormCheck.Label
          className={classNames('d-block mr-1 w-100 text-overflow-ellipsis custom-font', facetPanelText)}
        >
          {props.displayValue ?? props.facet.value}
        </FormCheck.Label>
      </FormCheck>

      {(props.facet.count ?? 0) > 0 && !abTestingGroup && (
        <Badge className="rounded-pill" style={{ backgroundColor: '#e0e0e0', color: '#797979' }}>
          {new Intl.NumberFormat('en-US').format(props.facet.count ?? 0)}
        </Badge>
      )}
    </Dropdown.Item>
  );
};

export interface FacetValueProps extends NodeProps {
  treeNode?: boolean;
  isExpanded?: boolean;
  onExpandToggle?: (expanded: boolean) => void;
}

// TODO: This should be split into two components. `TreeFacetValue` and `FacetValue`
export const FacetValue: React.FC<FacetValueProps> = ({ treeNode, isExpanded, onExpandToggle, ...nodeProps }) => {
  // TODO: Explore if this can be used directly from props.
  const [isOpen, setIsOpen] = useState(isExpanded);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  useEffect(() => setIsOpen(isExpanded), [isExpanded]);

  //const isFacetCountPresent = (nodeProps.facet.count ?? 0) > 0;

  const handleToggle = useCallback(() => {
    setIsOpen(isOpen => {
      onExpandToggle?.(!isOpen);
      return !isOpen;
    });
  }, [onExpandToggle]);

  return (
    <div className="d-flex justify-content-between align-items-center pl-2 label-width">
      {/* Using visibility instead of conditional rendering to fix alignment of all options */}
      {!abTestingGroup && (
        <FontAwesomeIcon
          icon={isOpen ? faCaretDown : faCaretRight}
          className="filter-caret text-grey"
          onClick={handleToggle}
          style={{ visibility: treeNode ? 'visible' : 'hidden' }}
        />
      )}
      <Node {...nodeProps} className={classNames(nodeProps.className, 'px-2')} />
    </div>
  );
};

export default FacetValue;
