import { AppContext, IAssetAttributeTypeProps } from '@amgen/shared';
import { removeSpecialCharacter } from '@amgen/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import './clampped-content-attribute.scss';

import FullContentPopup from '../full-content-popup/full-content-popup';

export interface ClamppedContentAttributeProps extends IAssetAttributeTypeProps {
  isAssetDetail?: boolean;
}

export const ClamppedContentAttribute: React.FC<ClamppedContentAttributeProps> = ({ isAssetDetail, ...props }) => {
  const { internalSources, readMoreSources } = useContext(AppContext);
  const [readMore, setReadMore] = useState(true);
  const isInternalSource = props.contentSource && internalSources?.includes(props?.contentSource);
  const showReadMore = props.contentSource && readMoreSources?.includes(props?.contentSource);
  const [showPopup, setShowPopup] = useState(false);
  const [characterCountCheck, setCharacterCountCheck] = useState(false);
  const [wordLengthCheck, setcheck] = useState(true);
  const setInnerHTML = removeSpecialCharacter(props.value?.toString().replace(/(\r\n|\n|\r)/gm, ' ') || '');
  //to check for no space and long word length in the content which causes UI break, solution is to add line break anywhere for such content
  //const stringCheck = setInnerHTML.substring(0, 100).includes(' ');
  const stringArray = setInnerHTML.split(/(\s+)/).slice(0, 35);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const wordLengthFlag = stringArray.find(item => item.length > 100);
    if (wordLengthFlag) {
      setcheck(false);
    }

    if (contentRef.current && contentRef.current.offsetHeight < contentRef.current.scrollHeight) {
      setCharacterCountCheck(true);
    }
  }, [props.value, stringArray]);

  return (
    <>
      <div className={isAssetDetail ? 'w-100 pl-3' : ''}>
        <p
          dangerouslySetInnerHTML={{
            __html: setInnerHTML,
          }}
          ref={contentRef}
          className={
            wordLengthCheck
              ? 'mb-0 p-0 w-100 solr-highlighted-content'
              : 'mb-0 p-0 w-100 solr-highlighted-content line-break'
          }
          id={Math.random().toString()}
        />
        {characterCountCheck && abTestingGroup && (
          <Button variant="link" className="p-0 fs-13" onClick={() => setShowPopup(true)}>
            Read More...
          </Button>
        )}
        {/*!readMore && isAssetDetail && <FullContent assetId={props.assetId} show={readMore} />*/}
        {/*isAssetDetail && (
          <Button variant="link" className="p-0 fs-13" onClick={() => setReadMore(!readMore)}>
            {readMore ? ' Read More...' : 'Read Less...'}
          </Button>
        )*/}
        {props.contentSource && !showReadMore
          ? ''
          : !isAssetDetail &&
            !abTestingGroup && (
              <Button variant="link" className="p-0 fs-13" onClick={() => setShowPopup(true)}>
                Read More...
              </Button>
            )}
      </div>
      {/* TODO: There is alraedy a component for this. But duplicated here to avoid circular dependency. Remove this duplication in future. */}
      {showPopup && (
        <FullContentPopup
          content={setInnerHTML}
          assetId={props.assetId}
          show={showPopup}
          onHide={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default ClamppedContentAttribute;
