import { faInfoCircle } from '@amgen/assets';
import { ApplicationName } from '@amgen/core';
import { AppContext, SearchContext } from '@amgen/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './result-count-window.scss';

import {
  SearchPageContext,
} from '../../../../../feature-result-views/src/lib/components/search-page/search-page-container/context';

export interface ResultCountWindowProps {
  itemsPerPage: number;
  resultCount: number;
  pageNo: number;
}

export const ResultCountWindow: React.FC<ResultCountWindowProps> = props => {
  const { emptySearchResult } = useContext(SearchPageContext);
  const { showTable, results } = useContext(SearchContext);
  const { selectedResultTab } = useContext(AppContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const itemPerPage =
    props.resultCount < props.itemsPerPage
      ? props.resultCount
      : props.resultCount - (props.pageNo - 1) * 15 < 15
      ? props.resultCount - (props.pageNo - 1) * 15
      : props.itemsPerPage;
  const start = props.resultCount === 0 ? 0 : (props.pageNo - 1) * props.itemsPerPage + 1;
  const end =
    appName === ApplicationName.EEA
      ? Math.max(props.pageNo * itemPerPage, props.resultCount <= 100 ? props.resultCount : 100)
      : Math.max(props.pageNo * itemPerPage, props.resultCount);

  const formattedResultCountForEEA =
    props.resultCount <= 100 ? new Intl.NumberFormat('en-US').format(props.resultCount) : 100;

  const formattedResultCount = new Intl.NumberFormat('en-US').format(props.resultCount); //
  console.log(start + results?.length - 1, 'result count');
  const resultCount = appName === ApplicationName.EEA ? formattedResultCountForEEA : formattedResultCount;
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const legalSearchCount = showTable === true ? formattedResultCount : resultCount;
  const checkCountCondition = end > Number(legalSearchCount);
  const endCount = end - (start - 1) < 15 ? start + (end - start) : start + 14;
  const countTextUpdate =
    abTestingGroup && currentCustomsource !== 'Contract Search'
      ? `Top Search Results`
      : props.resultCount > 1000 && appName === ApplicationName.EEA
      ? `Top 1000+ Results`
      : `Results ${start}-${endCount} of ${checkCountCondition ? end : legalSearchCount} `;

  const iconText =
    props.resultCount > 100 && showTable !== true && appName === ApplicationName.EEA
      ? `Top 100+ results are visible out of ${formattedResultCount} Authorized documents`
      : `Only Authorized documents are visible`;

  return (
    <Col
      xs="12"
      md="auto"
      className="result-count result-count-position link-grey px-0 my-auto order-3 order-lg-1 d-flex justify-content-start align-items-center"
    >
      {selectedResultTab === 'All Results' && (
        <>
          <span>{!emptySearchResult ? `${countTextUpdate}` : 'No Results'}</span>
          {!abTestingGroup && (
            <span>
              <OverlayTrigger placement="right" overlay={<Tooltip id="result-count-info-tooltip">{iconText}</Tooltip>}>
                <FontAwesomeIcon className="mx-1" icon={faInfoCircle} />
              </OverlayTrigger>
            </span>
          )}
        </>
      )}
    </Col>
    /* {selectedResultTab === 'Recommended' && (
        <div className="col-12 recommendation-count pb-2">
          <b>{topResultCount}</b> recommendations found
        </div>
      )} */
  );
};

export default ResultCountWindow;
