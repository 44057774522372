import { EnvironmentContext } from '@amgen/core';
import { AppliedFiltersBar } from '@amgen/feature-facets';
import {
  AppContext,
  FACET_FEATURE_ACTIONS,
  FacetContext,
  MatomoContext,
  PreferenceContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  useWindowSizeListener,
} from '@amgen/shared';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomGroupModal from 'apps/eea-search/src/app/components/custom-group-modal/custom-group-modal';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

import './top-search-filters.scss';

import CustomSourcesFilters from './custom-sources-filters';
import TopChildFilters from './top-child-filters';

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line */
export interface TopSearchFiltersProps {
  classnames?: string;
  topFilterData?: any;
}
enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
}

export function TopSearchFilters({ classnames, topFilterData }: TopSearchFiltersProps) {
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  //const [selectedParentFilter, setSelectedParentFilter] = useState('Internal');
  const {
    expandPanel,
    setExpandPanel,
    setSelectedTopFilter,
    setAllSelected,
    countData,
    selectedParentFilter,
    setSelectedParentFilter,
    selectedViewType,
    results,
    appData,
  } = useContext(SearchContext);
  const { createdCustomSource } = useContext(PreferenceContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { appliedFilters, isCustomFilterSearch, setCustomFilterSearch, setAppliedFilters } = useContext(FacetContext);
  const {
    isCustomSourceActive,
    setCustomSourceActive,
    setSelectedCustomSourceName,
    landingParent,
    setCurrentSource,
    currentSource,
    backButtonClicked,
    setBackButtonClicked,
    // horizonCompanyCode,
  } = useContext(AppContext);
  const envContext = useContext(EnvironmentContext);
  const parentSources = appName === 'EEA' ? ['Internal', 'External', 'All'] : ['Internal'];
  const parentSourcesABTesting = appName === 'EEA' ? ['All'] : ['All'];
  const [show, setShow] = useState(false);
  const searchCountData = countData && JSON.parse(countData.getSearchCount);
  // const { expandPanel, setExpandPanel } = useContext(SearchContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const validSources = abTestingGroup
    ? parentSourcesABTesting
    : searchCountData?.internal === 0 && searchCountData.external === 0 && searchCountData.external === 0
    ? []
    : searchCountData?.internal === 0
    ? ['External', 'All']
    : searchCountData?.external === 0
    ? ['Internal', 'All']
    : searchCountData?.internal === 0 && searchCountData?.external === 0
    ? ['All']
    : parentSources;

  const isMobile = useWindowSizeListener();
  const [showChildFilters, setShowChildFilters] = useState(true);
  // const [showParent, setShowParent] = useState('Internal');
  const [showParent, setShowParent] = useState(currentSource);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const sessionUserData = sessionStorage.getItem('user') || 'user';
  const companyCodeUserDetails = JSON.parse(sessionUserData)?.companyCode;

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.floor(num * 10) / 10);
  }
  // function makeFriendly(num) {
  //   if (num && num >= 1000000) return intlFormat(num / 1000000) + 'M';
  //   if (num && num >= 1000) return intlFormat(num / 1000) + 'K';
  //   if (num) {
  //     return intlFormat(num);
  //   }
  // }
  // const internalCount = makeFriendly(searchCountData?.internal);
  // const externalCount = makeFriendly(searchCountData?.external);
  // const allCount = makeFriendly(searchCountData?.all);

  const setParent = (parent, flag) => {
    if (parent != showParent) {
      setShowChildFilters(true);
      setShowParent(parent);
      sessionStorage.setItem(
        'isLegal',
        parent === 'Custom Source' && currentCustomsource === 'Contract Search' ? 'true' : 'false' || 'false'
      );
      setAppliedFilters(appliedFilters.removeAll('doc_nav_prefix'));
      setSelectedParentFilter(parent);
      setSelectedTopFilter('All');
      if (parent === 'Custom Source') {
        setCurrentSource('all');
        setSelectedCustomSourceName('All Sources'); //Neha - added this, when user switch top filter then it should select "All"
      } else {
        setCurrentSource(parent.toLowerCase());
      }
      if (currentCustomsource === 'Contract Search') {
        setSelectedCustomSourceName('All Sources');
        sessionStorage.setItem('current-source-selected', 'All Sources');
        const event = new Event('sessionStorageChange');
        window.dispatchEvent(event);
        // const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
        // envContext?.setEnvGraphqlUrl(abTestingGroup ? `${initialGraphAPIUrl}/v2` : initialGraphAPIUrl);
      }
      if (currentCustomsource === 'OpsKM Search') {
        const redirectTo = `${window.location.href}&pk_source=OpsKM&pk_campaign=widget&pk_medium=search-box&pk_filter=Ops%20KM%20Assets`;
        window.open(`${DOMPurify.sanitize(redirectTo)}`, '_self');
        return;
      }

      setCustomSourceActive(flag);
      // if (parent === 'Internal') {
      //   setAllSelected(true);
      // } else {
      //   setAllSelected(false);
      // }
      setBackButtonClicked(false); //Commented this condition in UseEffect and added here, only setting false when user switch tab
      setCustomFilterSearch?.(false);
    } else {
      setShowChildFilters(!showChildFilters);
    }
  };

  useEffect(() => {
    if (isCustomFilterSearch) {
      if (abTestingGroup) {
        setSelectedParentFilter('All');
        setCurrentSource('all');
      } else {
        if (landingParent === 'Internal Sources') {
          setSelectedParentFilter('Internal');
          setCurrentSource('internal');
        } else if (landingParent === 'External Sources') {
          setSelectedParentFilter('External');
          setCurrentSource('external');
        } else {
          setSelectedParentFilter('All');
          setCurrentSource('all');
        }
      }
    }
  }, [isCustomFilterSearch]);

  useEffect(() => {
    if (abTestingGroup) {
      setSelectedTopFilter('All');
      setSelectedParentFilter('All');
    } else {
      if (currentSource === 'internal') {
        setSelectedTopFilter('All');
        setSelectedParentFilter('Internal');
      } else if (currentSource === 'external') {
        setSelectedParentFilter('External');
      } else if (currentSource === 'all') {
        setSelectedTopFilter('All');
        setSelectedParentFilter('All');
      }

      if (backButtonClicked) {
        setCurrentSource('external');
        setSelectedParentFilter('External');
      }
    }
    // setBackButtonClicked(false);
  }, [currentSource, backButtonClicked]);

  const childFilterShowHide = () => {
    setShowChildFilters(!showChildFilters);
  };

  useEffect(() => {
    if (createdCustomSource !== '') {
      setSelectedParentFilter('Custom Sources');
      setCustomSourceActive(true);
    }
  }, [createdCustomSource]);

  return results.length > 0 || (abTestingGroup && appData?.getSearchObjectsApps?.docs?.length > 0) ? (
    <div
      className={classNames(
        classnames,
        !expandPanel && !isMobile
          ? 'expanded-filter spotlight-container expanded-filter new-class'
          : 'spotlight-container'
      )}
    >
      {searchCountData?.internal > 0 || searchCountData?.external > 0 || searchCountData?.all > 0 || abTestingGroup ? (
        <Row
          id="parent-filter"
          className={showChildFilters ? 'no-gutters row w-100' : 'no-gutters row w-100 border-bottom'}
        >
          {appName === 'EEA' && (
            <Col md={9} className="custom-tab">
              <Nav className="align-items-center top-filters">
                {validSources.map((parent, index) => {
                  return (
                    <div className="text-center">
                      {selectedParentFilter === parent && !isCustomSourceActive && (
                        <img
                          alt="expand and collapse"
                          src={
                            showChildFilters
                              ? 'assets/images/top-filter-expand-logo.svg'
                              : 'assets/images/top-filter-collapse-logo.svg'
                          }
                          width="15px"
                          style={{ marginBottom: showChildFilters ? -7 : -11 }}
                          // className="filter-collapse"
                          onClick={childFilterShowHide}
                        />
                      )}

                      <Nav.Item
                        className={
                          selectedParentFilter === parent && !isCustomSourceActive ? '' : 'custom-border mt-3 pt-1'
                        }
                        key={index}
                      >
                        <div
                          onClick={() => {
                            setParent(parent, false);
                          }}
                        >
                          <Nav.Link
                            className={classNames(
                              'mb-0 align-items-center d-flex justify-content-center',
                              selectedParentFilter === parent && !isCustomSourceActive ? 'active active-border' : '',
                              index === 0 && selectedParentFilter !== parent ? 'pl-0' : ''
                            )}
                          >
                            <div
                              className={
                                selectedParentFilter === parent && !isCustomSourceActive
                                  ? 'active-parent top-filter-text pt-1'
                                  : 'parent top-filter-text pt-1'
                              }
                            >
                              {' '}
                              {parent === 'Internal' ? 'Amgen' : parent} Sources{' '}
                              {/* {parent === 'Internal' && internalCount
                          ? internalCount
                          : parent === 'External' && externalCount
                          ? externalCount
                          : parent === 'All' && allCount
                          ? allCount
                          : ''} */}
                            </div>
                          </Nav.Link>
                        </div>
                      </Nav.Item>
                    </div>
                  );
                })}
                {searchCountData?.internal > 0 ||
                searchCountData?.external > 0 ||
                searchCountData?.all > 0 ||
                abTestingGroup ? (
                  <div className="text-center">
                    {selectedParentFilter === 'Custom Sources' || isCustomSourceActive ? (
                      <img
                        alt="expand and collapse"
                        src={
                          showChildFilters
                            ? 'assets/images/top-filter-expand-logo.svg'
                            : 'assets/images/top-filter-collapse-logo.svg'
                        }
                        width="15px"
                        style={{ marginBottom: showChildFilters ? -7 : -9 }}
                        onClick={childFilterShowHide}
                      />
                    ) : (
                      <></>
                    )}
                    <Nav.Item
                      className={
                        selectedParentFilter === 'Custom Sources' || isCustomSourceActive
                          ? ''
                          : 'custom-border mt-3 pt-1'
                      }
                    >
                      <div
                        onClick={() => {
                          setSelectedTopFilter('');
                          setParent('Custom Source', true);
                          // setSelectedCustomSourceName('All Sources');
                        }}
                      >
                        {/* Neha - hiding custom source for horizon employees */}
                        {/* {horizonCompanyCode ? (
                          ''
                        ) : (
                           // Aswathy -Nav Links were here before
                        )} */}

                        <Nav.Link
                          className={classNames(
                            'mb-0 align-items-center d-flex justify-content-center',
                            selectedParentFilter === 'Custom Sources' || isCustomSourceActive
                              ? 'active active-border'
                              : ''
                          )}
                        >
                          <div
                            className={
                              selectedParentFilter === 'Custom Sources' || isCustomSourceActive
                                ? 'active-parent top-filter-text pt-1'
                                : 'parent top-filter-text pt-1'
                            }
                          >
                            {' '}
                            Custom Sources
                          </div>
                        </Nav.Link>
                      </div>
                    </Nav.Item>
                  </div>
                ) : (
                  ''
                )}
              </Nav>
            </Col>
          )}
          <Col
            md={3}
            className={
              appliedFilters.count > 0
                ? selectedViewType === ResultViewType.Table
                  ? 'd-flex advance-filter-table-view'
                  : 'd-flex advance-filter-with-clear '
                : 'd-flex advance-filter-without-clear '
            }
            // className={appliedFilters.count > 0 ? 'advance-filter-with-clear ' : 'advance-filter-without-clear '}
            // custom-group-container p-0
          >
            <>
              <div className="p-0" title="Create Custom Group">
                {/* horizon condition was here before - appName === 'EEA' && !horizonCompanyCode */}
                {appName === 'EEA' ? (
                  searchCountData?.internal > 0 ||
                  searchCountData?.external > 0 ||
                  searchCountData?.all > 0 ||
                  abTestingGroup ? (
                    <div
                      className="create-custom-group"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} className="create-custom-btn cursor-pointer" />
                      <div className="fs-12 pl-1 font-weight-bolder custom-group-text">Custom Group</div>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <></>
                )}
              </div>
              <div
                className="d-flex ml-2 advance-filter-icon-cursor"
                onClick={() => {
                  setExpandPanel?.(true);
                  trackApplicationFeature(
                    SEARCH_APP_FEATURE_LIST.FACET_FEATURE,
                    FACET_FEATURE_ACTIONS.ADVANCED_FILTERS_CLICKED
                  );
                }}
                title="Advanced Filter(s)"
              >
                <span className="mr-1">
                  {appliedFilters?.count > 0 && (
                    <div className="bg-info rounded-circle filter-count">{appliedFilters?.count}</div>
                  )}
                  <img
                    alt="advance filter"
                    src={'assets/images/advacne-filter-logo.svg'}
                    className="advance-filter-icon"
                  ></img>
                </span>
                <span className="align-items-center d-flex fs-14">Filter(s)</span>
              </div>

              <AppliedFiltersBar isClearable parentSource="topFilter" />
            </>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {searchCountData?.internal > 0 || searchCountData?.external > 0 || searchCountData?.all > 0 || abTestingGroup
        ? selectedParentFilter === 'Custom Sources' || isCustomSourceActive
          ? showChildFilters && (
              <CustomSourcesFilters selectedParentFilter={selectedParentFilter}></CustomSourcesFilters>
            )
          : showChildFilters && (
              <TopChildFilters
                selectedParentFilter={selectedParentFilter}
                topFilterData={topFilterData}
              ></TopChildFilters>
            )
        : ''}
      {show ? (
        <CustomGroupModal
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      ) : (
        ''
      )}
    </div>
  ) : (
    <></>
  );
}

export default TopSearchFilters;
